import React from "react";
import Router from "./app.route";

export default function App() {
  return (
    
    <>
      <Router />
    </>
  );
}
