import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "./breadcrumb";
import "./layout.css";
import NavBar from "./sideBar";
import TopBanner from "./topBanner";
import { authTokenSelector } from "../../store/selectors/auth.selector";
import { useSelector, useDispatch } from "react-redux";
import { permissionListSelector, totalPermissionListSelector } from "../../store/selectors/app.selector";

export default function Layout({ children }) {
    const authToken = useSelector(authTokenSelector);
    const navigate = useNavigate();
    const menuList = useSelector(permissionListSelector);
    const totalMenuList = useSelector(totalPermissionListSelector);
    const [menuName, setMenuName]= useState()
    const location = useLocation()
    const [openKeys, setOpenKeys] = useState(["0"]);
    const root = '/' + location.pathname.split('/')[1]

    useEffect(() => {
      if (authToken === null) {
        navigate("/login");
      }
    }, [authToken]);

    const onChange = useCallback(
      async (value) => {
        try {
          menuList.map((options, idx) => {
            options["subMenus"] &&
              options["subMenus"].map((menu, index) => {
                if (root === menu.pathName) {
                  setMenuName(menu.menuName)
                }
              })
          })
        } catch (error) {
          console.log(error);
        }
      },
      []
    );

    const onOpenChange = (keys) => {
      const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
      if (menuList.indexOf(latestOpenKey) === -1) {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      } else {
        setOpenKeys(keys);
      }
    };

  return (
    <div className="app-container">
      <div className="top-banner">
        <TopBanner onChange={onChange} menuName={menuName} setMenuName={setMenuName} menuList={menuList} />
      </div>
      <div className="render-section">
        <div className="left-section">
          <NavBar onOpenChange = {onOpenChange} openKeys={openKeys} menuList={menuList} totalMenuList={totalMenuList}/>
        </div>
        <div className="right-section">
          {children}
          <div style={{height:30}}></div>
        </div>
      </div>
    </div>
  );
}
