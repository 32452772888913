import {
  SET_APP_LANGUAGE,
  SET_LOGIN_DETAILS,
  SET_PERMISSION_LIST,
  SET_TOTAL_PERMISSION_LIST,
  SET_USER_PROFILE,
  SET_BRANCH_LIST
  
} from "../constants";

export const setAppLanguage = (payload) => ({
  type: SET_APP_LANGUAGE,
  payload,
});
export const setLoginDetails = (payload) => ({
  type: SET_LOGIN_DETAILS,
  payload,
});
export const setProfileDetails = (payload) => ({
  type: SET_USER_PROFILE,
  payload,
});
export const setPermissionList = (payload) => ({
  type: SET_PERMISSION_LIST,
  payload,
});

export const setTotalPermissionList = (payload) => ({
  type: SET_TOTAL_PERMISSION_LIST,
  payload,
});


export const setBranchList = (branchList) => ({
  type: SET_BRANCH_LIST,
  payload: branchList,
});

