export default {
  GERENERATEKEY: "/keyexchange/generateKey",
  LOGIN: "/user/login",
  WEBPORTAL: "user/portallogin",
  LOGOUT: "/user/logout",
  REFRESH_TOKEN: "/user/refreshToken",
  BRANCH_SETTING: "/branchSetting/setting/1",
  ALL_COUNTS: "/authorisationqueue/count",
  ALL_MENUS: "/user/menu",

  GET_PARENT_MENUS: "/access/getParentMenus",
  GET_ALL_MENUS: "/access/getAllMenus",
  GET_MENUS: "/access/getMenus",
  USER_FORCE_CHANGE_PASSWORD: "/user/forceChangepwd",
  CHANGE_PASSWORD_VERIFY: "/user/changePasswordVerify",
  CHANGE_PASSWORD: "/user/changepassword",
  USER_UNLOCK_ACCOUNT: "/user/unlockuseraccount",
  FORGOT_PASSWORD: "/user/ForgotPassword",
  ALL_USER_LIST: "/user/getalluser",
  ACTIVATE_DEACTIVATE_ACCOUNT: "/user/blockunblockuser",
  AGENT_PIN_RESET: "/user/restpin",
  UNBLOCK_USER_ACCOUNT: "/user/unblockUserAccount",
  FORGOT_PASSWORD_VALIDATE_USER: "/user/ValidateUser",
  USER_PASSWORD_RESET: "/user/resetpassword",





  ROLE_FIND: "/role/filter",
  ROLE_OPERATION: "/role",
  ROLE_AUTHORISE: "/role/authorise",
  UNASSIGNED_ROLE: "/role/getunassignedrolelist",


  SCREEN_FIND: "/screen/filter",
  SCREEN_OPERATION: "/screen",

  ROLE_SCREEN_FIND: "/rolescreen/filter",
  ROLE_SCREEN_OPERATION: "/rolescreen",
  ROLE_SCREEN_AUTHORIZE: "/rolescreen/authorise",

  USER_FIND: "/user/filter",
  USER_OPERATION: "/user",
  USER_AUTHORIZE: "/user/authorise",

  USER_BRANCH: "/userbranch",
  USER_BRANCH_AUTHORIZE: "/userbranch/authorise",
  USER_BRANCH_FILTER: "/userbranch/filter",
  FETCH_CBS_USER: "/user/getcbscustomerdetails",


  FAQ_FIND: "/faq/getAll",
  FAQ_CREATE: "/faq/create",
  FAQ_MODIFY: "/faq/modify",
  FAQ_AUTHORIZE: "/faq/authorize",
  FAQ_DELETE: "/faq/delete",

  LOCATION_FIND: "/location/find",
  LOCATION_CREATE: "/location/create",
  LOCATION_MODIFY: "/location/modify",
  LOCATION_AUTHORIZE: "/location/authorize",
  LOCATION_DELETE: "/location/delete",

  OFFERS_FIND: "/offer/find",
  OFFERS_CREATE: "/offer/create",
  OFFERS_MODIFY: "/offer/modify",
  OFFERS_AUTHORIZE: "/offer/authorize",
  OFFERS_DELETE: "/offer/delete",

  SECURITY_FIND: "/securityquestions/find",
  SECURITY_CREATE: "/securityquestions/create",
  SECURITY_MODIFY: "/securityquestions/modify",
  SECURITY_AUTHORIZE: "/securityquestions/authorize",
  SECURITY_DELETE: "/securityquestions/delete",

  // Application Parameter Module
  PARAMETER_FIND: "/parameter/find",
  PARAMETER_DELETE: "/parameter/delete",
  PARAMETER_AUTHORIZE: "/parameter/authorize",
  PARAMETER_MODIFY: "/parameter/modify",
  PARAMETER_MODULE_NAMES: "/parameter/getModuleNames",
  PARAMETER_CATEGORY_NAMES: "/parameter/getCategoryNames",
  PARAMETER_APP_PARAMETERS: "/parameter/getListOfAppParameters",

  // Terms and Conditions Module
  TERM_CONDITION_FIND: "/termsandcondition/getAll",
  TERM_CONDITION_CREATE: "/termsandcondition/create",
  TERM_CONDITION_MODIFY: "/termsandcondition/modify",
  TERM_CONDITION_DELETE: "/termsandcondition/delete",
  TERM_CONDITION_AUTHORIZE: "/termsandcondition/authorize",
  TERM_CONDITION_FILEUPLOAD: "/termsandcondition/fileupload",
  TERM_CONDITION_DOWNLOAD: "/termsandcondition/download",
  TERM_CONDITION_MODIFY_RECORD: "/termsandcondition/modifyrecord",

  // Privacy Policy Module
  PRIVACY_POLICY_FIND: "/privacyPolicy/getAllRecords",
  PRIVACY_POLICY_DELETE: "/privacyPolicy/delete",
  PRIVACY_POLICY_AUTHORIZE: "/privacyPolicy/auth",
  PRIVACY_POLICY_UPLOAD: "/privacyPolicy/upload",
  PRIVACY_POLICY_MODIFY: "/privacyPolicy/modify",
  PRIVACY_POLICY_MODIFY_RECORD: "/privacyPolicy/modifyRecord",
  PRIVACY_POLICY_DOWNLOAD: "/privacyPolicy/download",

  MOBILE_REG_VERIFY: "/customerReg/customerRegVerify",
  MOBILE_REG_SUBMIT: "/customerReg/customerRegSubmit",
  MOBILE_REG_AUTH: "/customerReg/customerRegAuth",
  MOBILE_REG_CIF_VERIFY: "/mobileReg/mobileCifVerify",
  MOBILE_REG_ENQUIRY: "/customerReg/customerEnquiry",
  MOBILE_REG_ADD_CIF: "/customerReg/addCif",
  MOBILE_REG_CIF_AUTH: "/customerReg/addCifAuthorize",
  MOBILE_REG_FIND: "/customerReg/search",
  MOBILE_REG_DELETE: "/customerReg/deleteCustReg",
  MOBILE_REG_VIEW_DETAILS: "/customerReg/custbankenquirywithversion",
  MOBILE_REG_MODIFY: "/customerReg/modifyCustReg",
  MOBILE_REG_MODIFY_MOBILE_NO: "/custRequest/changeMobileNo",
  MOBILE_REG__MOBILE_NO_DELETE: "/custRequest/changeMobNoDelete",
  MOBILE_REG_GET_CUSTOMER_DETAILS: "/customerReg/getCustDetails",
  MOBILE_REG_VERIFY_CIF_MAP: "/customerReg/verifyCifMap",
  MOBILE_REG_MODIFY_MOBILE_NO_AUTH: "/custRequest/changeMobileNoAuth",
  MOBILE_REG_MODIFY_EMAIL: "/custRequest/changeEmailId",
  MOBILE_REG_MODIFY_EMAIL_AUTH: "/custRequest/changeEmailIdAuth",
  MOBILE_REG_MODIFY_MOBILE_NO_FIND: "/custRequest/findMobileNoChange",
  MOBILE_REG_MODIFY_EMAIL_FIND: "/custRequest/findEmailChange",
  MOBILE_REG_MODIFY_EMAIL_DELETE: "/custRequest/changeEmailDelete",

  AUTH_FLOW_CREATE: "/payAuth/create",
  AUTH_FLOW_DELETE: "/payAuth/delete",
  AUTH_FLOW_FIND: "/payAuth/getAll",
  AUTH_FLOW_MODIFY: "/payAuth/modify",
  AUTH_FLOW_AUTHORIZE: "/payAuth/authorize",

  AUTH_RULE_FIND: "/accttxnrule/findInitRecords",
  AUTH_RULE_CREATE: "/accttxnrule/create",
  AUTH_RULE_MODIFY: "/accttxnrule/modify",
  AUTH_RULE_AUTHORIZE: "/accttxnrule/authorize",
  AUTH_RULE_DELETE: "/accttxnrule/delete",
  GET_AUTH_RULES: "/accttxnrule/getauthrules",

  ACC_TXN_LIMT_FIND: "/accTxnLimit/search",
  ACC_TXN_LIMT_CREATE: "/accTxnLimit/create",
  ACC_TXN_LIMT_MODIFY: "/accTxnLimit/modify",
  ACC_TXN_LIMT_AUTHORIZE: "/accTxnLimit/authorize",
  ACC_TXN_LIMT_DELETE: "/accTxnLimit/delete",
  ACC_TXN_LIMT_SEARCH: "/accTxnLimit/search",

  ADD_CIF_CREATE: "/custAccOps/addCif",
  ADD_CIF_DELETE: "/custAccOps/addcifdelete",
  ADD_CIF_AUTHORIZE: "/custAccOps/authorize",
  ADD_CIF_FIND: "/custAccOps/getInitRecord",
  ADD_CIF_GET_ALL: "/custAccOps/getAll",
  GET_CIF_CUST_DETAILS: "/custAccOps/getCifCustDetails",
  DELETE_CIF_CREATE: "/custAccOps/deleteCifCreate",
  GET_ALL_DELETE_CIF: "/custAccOps/getAllDelCif",
  GET_ALL_DELETE_PROFILE: "/custAccOps/getAllDelProfile",
  GET_ALL_DELETE_ACCOUNTS: "/custAccOps/getAllDelAccounts",
  DELETE_CIF_AUTHORIZE: "/custAccOps/deleteCifAuthorize",
  DELETE_CIF_DELETE: "/custAccOps/deleteDelCifInit",
  GET_DELETE_CIF_DETAILS: "/custAccOps/getDelCifInitRecord",

  SHOW_OR_HIDE_ACCOUNT_CREATE: "/showOrHideAccount/create",
  SHOW_OR_HIDE_ACCOUNT_FIND: "/showOrHideAccount/getAll",
  SHOW_OR_HIDE_ACCOUNT_MODIFY: "/showOrHideAccount/modify",
  SHOW_OR_HIDE_ACCOUNT_DELETE: "/showOrHideAccount/delete",
  SHOW_OR_HIDE_ACCOUNT_AUTHORIZE: "/showOrHideAccount/authorize",

  STOP_CHEQUE_MODIFY: "/stopchequereason/modify",
  STOP_CHEQUE_GET_ALL: "/stopchequereason/getAll",
  STOP_CHEQUE_DELETE: "/stopchequereason/delete",
  STOP_CHEQUE_CREATE: "/stopchequereason/create",
  STOP_CHEQUE_AUTHORIZE: "/stopchequereason/authorize",

  REPORT_MODIFY: "/report/modify",
  REPORT_GET_VERSION: "/report/getVersion",
  REPORT_VERSION_HISTORY: "/report/getVersionHistory",
  REPORT_FIND: "/report/find",
  REPORT_DOWNLOAD: "/report/download",
  REPORT_DELETE: "/report/delete",
  REPORT_CREATE: "/report/create",
  REPORT_AUTHORIZE: "/report/authorize",

  TD_MODIFY: "/termDeposit/modify",
  TD_PRODUCT_VALUES: "/termDeposit/getProductDropDownValues",
  TD_PRODUCT_DETAILS: "/termDeposit/getProductDetails",
  TD_FIND: "/termDeposit/find",
  TD_DELETE: "/termDeposit/delete",
  TD_CREATE: "/termDeposit/create",
  TD_AUTHORIZE: "/termDeposit/authorize",

  CARD_CODE_SEARCH: "/cardCode/search",
  CARD_CODE_MODIFY: "/cardCode/modify",
  CARD_CODE_DELETE: "/cardCode/delete",
  CARD_CODE_CREATE: "/cardCode/create",
  CARD_CODE_AUTHORIZE: "/cardCode/authorize",
  CARD_CODE_GET_ALL: "/cardCode/getAll",
  CARD_CODE_DETAILS: "/cardCode/getCardCode",

  CARD_CODE_UPLOAD_GET_ALL: "/cardCode/getAllCardCodeFile",
  CARD_CODE_UPLOAD_CREATE: "/cardCode/uploadCardCodes",
  CARD_CODE_UPLOAD_AUTHORIZE: "/cardCode/authorizeCardCodeFile",
  CARD_CODE_UPLOAD_MODIFY: "/cardCode/modifyCardCodeFile",
  CARD_CODE_UPLOAD_DELETE: "/cardCode/deleteCardCodeFile",

  REMARKS_MODIFY: "/remark/modify",
  REMARKS_DELETE: "/remark/delete",
  REMARKS_CREATE: "/remark/create",
  REMARKS_AUTHORIZE: "/remark/authorize",
  REMARKS_GET_ALL: "/remark/getAll",

  updatePayeeCoolingOverride: "/payee/updatePayeeCoolingOverride",
  getAllPayee: "/payee/getAllPayee",

  CORPORATE_GROUP_VALIDATE_CIF: "/corporateGroup/validateCifId",
  CORPORATE_GROUP_MODIFY: "/corporateGroup/modify",
  CORPORATE_GROUP_GETALL: "/corporateGroup/getAll",
  CORPORATE_GROUP_GET_ALL_CIFS: "/corporateGroup/getAllCifIds",
  CORPORATE_GROUP_DELETE: "/corporateGroup/delete",
  CORPORATE_GROUP_CREATE: "/corporateGroup/create",
  CORPORATE_GROUP_AUTHORIZE: "/corporateGroup/authorize",
  KYC_GET_ALL: "/kycUpdate/getAll",
  KYC_DOWNLOAD: "/kycUpdate/download",

  USER_BLOCK_UNBLOCK_GET_ALL: "/blockOrUnblock/getAll",
  USER_BLOCK_UNBLOCK_DELETE: "/blockOrUnblock/delete",
  USER_BLOCK_UNBLOCK_CREATE: "/blockOrUnblock/create",
  USER_BLOCK_UNBLOCK_AUTHORIZE: "/blockOrUnblock/authorize",

  UN_FREEZE_ACCOUNT_MODIFY: "/freezeOrUnFreeze/modify",
  UN_FREEZE_ACCOUNT_GETALL: "/freezeOrUnFreeze/getAll",
  UN_FREEZE_ACCOUNT_AUTHORIZE: "/freezeOrUnFreeze/authorize",
  UN_FREEZE_ACCOUNT_DELETE: "/freezeOrUnFreeze/delete",

  NOTIFICATION_GET_ALL: "/notification/getAll",
  NOTIFICATION_DELETE: "/notification/delete",
  NOTIFICATION_CREATE: "/notification/create",
  NOTIFICATION_AUTHORIZE: "/notification/authorize",
  NOTIFICATION_MODIFY: "/notification/modify",

  USER_OPERATION_GET_ALL: "/userOperation/getAll",
  USER_OPERATION_MODIFY: "/userOperation/modify",
  USER_OPERATION_AUTHORIZE: "/userOperation/authorize",
  USER_OPERATION_DELETE: "/userOperation/delete",

  STATIC_FIELDS_GET_ALL: "/staticFields/getAll",
  STATIC_FIELDS_DELETE: "/staticFields/delete",
  STATIC_FIELDS_CREATE: "/staticFields/create",
  STATIC_FIELDS_AUTHORIZE: "/staticFields/authorize",
  STATIC_FIELDS_MODIFY: "/staticFields/modify",

  ENABLE_IB_MODIFY: "/enableIB/modify",
  ENABLE_IB_GET_ALL: "/enableIB/getAll",
  ENABLE_IB_DELETE: "/enableIB/delete",
  ENABLE_IB_AUTHORIZE: "/enableIB/authorize",
  CHEQUE_BOOK_FIND: "/chequeBook/find",
  CHEQUE_BOOK_MODIFY: "/chequeBook/modify",
  CHEQUE_BOOK_AUTHORIZE: "/chequeBook/authorize",

  TRANSACTIONS_INQUIRY_FIND: "/transactions/getAll",

  DEBIT_CARD_FIND: "/debitCard/find",
  DEBIT_CARD_MODIFY: "/debitCard/modify",
  DEBIT_CARD_AUTHORIZE: "/debitCard/authorize",

  SCHEDULED_TRANSACTION_FIND: "/transactions/getAllScheduledTransactions",
  CANCEL_SCHEDULED_TRANSACTION: "/transactions/cancelScheduledTransactions",
  BANK: "/bank",
  BRANCH_FIND: "/branch",
  BRANCH_DETAILS: "/branch/branchdetails",
  BRANCH_CREATE: "/branch/addBranch",
  BRANCH_MODIFY: "/branch/modify",
  BRANCH_DELETE: "/branch/delete",
  BRANCH_AUTHORIZE: "/branch/authorise",
  BRANCH_IFSC_FIND: "/branchIfsc/getAll",
  BRANCH_IFSC_CREATE: "/branchIfsc/addBranchIfsc",
  BRANCH_IFSC_MODIFY: "/branchIfsc/modify",
  BRANCH_IFSC_DELETE: "/branchIfsc/delete",
  BRANCH_IFSC_AUTHORIZE: "/branchIfsc/authorize",
  IB_GET_ALL: "/internetBanking/getAll",
  IB_MODIFY: "/internetBanking/modify",
  IB_AUTHORIZE: "/internetBanking/authorize",
  IB_DELETE: "/internetBanking/delete",

  VILLAGE: "/village",
  VILLAGE_AUTHORIZE: "/village/authorise",
  VILLAGE_FILTER: "/village/filter",
  COLLECTION_FILTER: "/collection/filter",

  DISTRICT: "/district",
  DISTRICT_AUTHORIZE: "/district/authorise",
  DISTRICT_FILTER: "/district/filter",

  TALUKA: "/taluka",
  TALUKA_AUTHORIZE: "/taluka/authorise",
  TALUKA_FILTER: "/taluka/filter",

  STATE: "/state",
  COLLECTION: "/collection",
  COLLECTION_FILTER: "/collection/filter",
  COLLECTION_AUTHORIZE: "/collection/authorise",
  BCCATEGORY: "/agentcategorymapping",
  BCCATEGORY_AUTHORIZE: "/agentcategorymapping/authorise",
  MASSTERDATA: "/masterdata",
  STATE_AUTHORIZE: "/state/authorise",
  STATE_FILTER: "/state/filter",
  BCCATEGORY_FILTER: "agentcategorymapping/filter",
  ALERT: "/alert",
  ALERT_FILTER: "/alert/filter",
  ALERT_AUTHORIZE: "/alert/authorise",

  BIOMETRIC: "/biometric",
  BIOMETRIC_FILTER: "/biometric/filter",
  BIOMETRIC_AUTHORIZE: "/biometric/authorise",
  AGENT: "/agent",
  AGENT_FILTER: "/agent/filter",
  AGENT_AUTHORIZE: "/agent/authorise",

  ACCOUNT_PARAMETER: "/account",
  ACCOUNT_PARAMETER_AUTHORIZE: "/account/authorise",
  ACCOUNT_PARAMETER_FILTER: "/account/filter",

  BANK_PARAMETER: "/bankparameter",
  BANK_PARAMETER_AUTHORIZE: "/bankparameter/authorise",
  BANK_PARAMETER_FILTER: "/bankparameter/filter",

  ACCOUNT_TYPE: "/accounttypelimits",
  ACCOUNT_TYPE_AUTHORIZE: "/accounttypelimits/authorise",
  ACCOUNT_TYPE_FILTER: "/accounttypelimits/filter",

  FDRD: "/fdrdtype",
  FDRD_AUTHORIZE: "/fdrdtype/authorise",
  FDRD_FILTER: "/fdrdtype/filter",

  RECURRING_DEPOSIT: "/recurringdeposit",
  RECURRING_DEPOSIT_AUTHORIZE: "/recurringdeposit/authorise",
  RECURRING_DEPOSIT_FILTER: "/recurringdeposit/filter",

  FILE_EXTENSION: "/fileextension",
  FILE_EXTENSION_AUTHORIZE: "/fileextension/authorise",
  FILE_EXTENSION_FILTER: "/fileextension/filter",

  FILE_UPLOAD: "/fileupload",
  FILE_UPLOAD_AUTHORIZE: "/fileupload/authorise",
  FILE_UPLOAD_FILTER: "/fileupload/filter",

  COLLECTION: "/collection",
  COLLECTION_FILTER: "/collection/filter",
  COLLECTION_AUTHORIZE: "/collection/authorise",
  AGENTLOCATIONMAPPING: "/bcagentlocationmapping",
  AGENTMAPPING_AUTHORIZE: "/bcagentlocationmapping/authorise",
  AGENTMAPPING_FILTER: "/bcagentlocationmapping/filter",
  STATE_AUTHORIZE: "/state/authorise",
  STATE_FILTER: "/state/filter",
  ALERT: "/alert",
  ALERT_FILTER: "/alert/filter",
  ALERT_AUTHORIZE: "/alert/authorise",

  COLLECTION: "/collection",
  COLLECTION_FILTER: "/collection/filter",
  COLLECTION_AUTHORIZE: "/collection/authorise",
  AGENTLOCATION: "/agencyagentoutletlocation",
  AGENTLOCATION_AUTHORIZE: "/agencyagentoutletlocation/authorise",
  AGENTLOCATION_FILTER: "/agencyagentoutletlocation/filter",
  STATE_AUTHORIZE: "/state/authorise",
  STATE_FILTER: "/state/filter",
  ALERT: "/alert",
  ALERT_FILTER: "/alert/filter",
  ALERT_AUTHORIZE: "/alert/authorise",
  BCAGENT_LOCCATION: "/bcagentlocation",
  BCAGENT_LOCATION_AGENCY: "/bcagentlocation/agencyloaction",

  CURENTACOUNT: "/currentaccountopen",
  CURRENTACCOUNT_AUTHORIZE: "/currentaccountopen/authorise",


  COLLECTION: "/collection",
  COLLECTION_FILTER: "/collection/filter",
  COLLECTION_AUTHORIZE: "/collection/authorise",
  USERCREATER: "/createagent",
  USERCREATER_AUTHORIZE: "/createagent/authorise",
  STATE_AUTHORIZE: "/state/authorise",
  STATE_FILTER: "/state/filter",
  USERCREATER_FILTER: "/createagent/filter",
  ALERT: "/alert",
  ALERT_FILTER: "/alert/filter",
  ALERT_AUTHORIZE: "/alert/authorise",



  SUPPORTPERSON: "/supportperson",
  SUPPORTPERSON_AUTHORIZE: "/supportperson/authorise",
  SUPPORTPERSON_FILTER: "/supportperson/filter",

  COLLECTION: "/collection",
  COLLECTION_FILTER: "/collection/filter",
  COLLECTION_AUTHORIZE: "/collection/authorise",
  CORPORATEAGENT: "/corporateagent",
  CORPORATEAGENT_AUTHORIZE: "/corporateagent/authorise",
  STATE_AUTHORIZE: "/state/authorise",
  STATE_FILTER: "/state/filter",
  CORPRATE_FILTER: "/corporateagent/filter",
  ALERT: "/alert",
  ALERT_FILTER: "/alert/filter",
  ALERT_AUTHORIZE: "/alert/authorise",

  COLLECTION: "/collection",
  COLLECTION_FILTER: "/collection/filter",
  COLLECTION_AUTHORIZE: "/collection/authorise",
  RECURRINGDEPOSITEINST: "/recurringdepositeinstallment",
  RECURRINGDEPOSITEINST_AUTHORIZE: "/recurringdepositeinstallment/authorise",
  STATE_AUTHORIZE: "/state/authorise",
  STATE_FILTER: "/state/filter",
  RECU_FILTER: "/recurringdepositeinstallment/filter",
  ALERT: "/alert",
  ALERT_FILTER: "/alert/filter",
  ALERT_AUTHORIZE: "/alert/authorise",

  COLLECTION: "/collection",
  COLLECTION_FILTER: "/collection/filter",
  COLLECTION_AUTHORIZE: "/collection/authorise",
  LOANLead: "/LoanLead",
  LOANLead_AUTHORIZE: "/LoanLead/authorise",
  STATE_AUTHORIZE: "/state/authorise",
  STATE_FILTER: "/state/filter",
  LOANLEAD_FILTER: "/LoanLead/filter",
  ALERT: "/alert",
  ALERT_FILTER: "/alert/filter",
  ALERT_AUTHORIZE: "/alert/authorise",

  COLLECTION: "/collection",
  COLLECTION_FILTER: "/collection/filter",
  COLLECTION_AUTHORIZE: "/collection/authorise",
  RECURRINGACCOUNT: "/recurringdepositeaccountopen",
  RECURRINGACCOUNT_AUTHORIZE: "/recurringdepositeaccountopen/authorise",
  STATE_AUTHORIZE: "/state/authorise",
  STATE_FILTER: "/state/filter",
  RECURRING_FILTER: "/recurringdepositeaccountopen/filter",
  ALERT: "/alert",
  ALERT_FILTER: "/alert/filter",
  ALERT_AUTHORIZE: "/alert/authorise",

  COLLECTION: "/collection",
  COLLECTION_FILTER: "/collection/filter",
  COLLECTION_AUTHORIZE: "/collection/authorise",
  AGENTENABLEDISABLED: "/agentenabledisable",
  AGENTENABLEDISABLED_AUTHORIZE: "/agentenabledisable/authorise",
  STATE_AUTHORIZE: "/state/authorise",
  STATE_FILTER: "/state/filter",
  AGENTENADIS_FILTER: "/agentenabledisable/filter",
  ALERT: "/alert",
  ALERT_FILTER: "/alert/filter",
  ALERT_AUTHORIZE: "/alert/authorise",

  COLLECTION: "/collection",
  COLLECTION_FILTER: "/collection/filter",
  COLLECTION_AUTHORIZE: "/collection/authorise",
  AGENTACCOUNT: "/agentaccountopen",
  AGENTACCOUNT_AUTHORIZE: "/agentaccountopen/authorise",
  STATE_AUTHORIZE: "/state/authorise",
  STATE_FILTER: "/state/filter",
  AGENTACC_FILTER: "/agentaccountopen/filter",
  ALERT: "/alert",
  ALERT_FILTER: "/alert/filter",
  ALERT_AUTHORIZE: "/alert/authorise",


  FUNDTRANSFER: "/interface/fundtransfer",
  FUNDTRANSFER_AUTHORIZE: "/interface/authorise",
  FUNDTRANSFER_FILTER: "/interface/filter",

  ALERT: "/alert",
  ALERT_FILTER: "/alert/filter",
  ALERT_AUTHORIZE: "/alert/authorise",


  AUTHORISATION_MAINTENANCE: "/authorisationqueue/maintenance",
  AUTHORISATION_TRANSACTION: "/authorisationqueue/transaction",

  SAVINGACCOUNT: "/savingaccountopen",
  SAVINGACCOUNT_FILTER: "/savingaccountopen/filter",
  SAVINGACCOUNT_AUTHORIZE: "/savingaccountopen/authorise",

  INTERFACE: "/interface",
  Interface_filter: "/inteface/filter",
  MENU: "/menu/filter",

  CORPORATECUSTOMER: "/customer",
  CUSTOMER_FILTER: "/customer/filter",
  CUSTOMER_AUTHORIZE: "/customer/authorise",

  RETAILCUSTOMER: "/retailcustomer",
  RETAILCUSTOMER_FILTER: "/retailcustomer/filter",
  RETAILCUSTOMER_AUTHORIZE: "/retailcustomer/authorise",

  GETCUSTOMERDETAILS: "/interface/getcustomerdetails",
  GETNOMINATION: "/interface/getnomineedetails",

  CASA_ACCOUNTDETAILS: "/interface/casaaccountdetails",
  FUNDS: "/interface/fundtransfer",


  COLLECTION: "/collection",
  COLLECTION_FILTER: "/collection/filter",
  COLLECTION_AUTHORIZE: "/collection/authorise",
  kycagent: "/kycagent",
  kycagent_AUTHORIZE: "/kycagent/authorise",
  STATE_AUTHORIZE: "/state/authorise",
  STATE_FILTER: "/state/filter",
  kycagent_FILTER: "/kycagent/filter",
  KYC_AGENTS_DETAILS_FILTER: '/kycagent/agencyfilter',
  ALERT: "/alert",
  ALERT_FILTER: "/alert/filter",
  ALERT_AUTHORIZE: "/alert/authorise",
  KYCAGENY_SENDKYCTOCBS: "/interface/sendkyctocbs",
  KYCAGENCY_getkycdetails: "/kycagent",
  KYC_INIDIVIDUAL_AGENT: "/agentregistration/filter",
  INIDIVIDUAL_AGENT_: "/agentregistration",
  KYCAGENT_CIF: "/kycagent/getbycif",



  FUNDS_DEPLOY: "/interface/namelookup",


  NAME_LOOK_UP: "/interface/namelookup",
  CASH_WITHDRWAL_Transaction: "/interface/Cashwithdrawal",
  CASH_DEPOSITE_Transaction: "/interface/cashdepoist",

  Bill_Payment: "/interface/billpaymenetlookup",

  BILLS: "/interface/billpayment",


  CREATE_AGENT_USER: "/user/createagentuser",

  AGENT_REGISTERD: "/report/registerdagentereports",
  // AGENT_TRANSACTION: "/report/datewisetransationreport",
  AGENT_TRANSACTION: "/report/alltransaction",
  AGENT_CASHDEPOSITE: "/report/cashdepositreport",
  AGENT_CASHWITHDRAW: "/report/cashwithdrawalreport",
  AGENT_CASHWITHDRAWl: "/report/cashwithdrawalreport",
  AGENT_CASHDEPOSITES: "/report/cashdepositreport",

  // AGENT_UTILITY: "/report/utilitytranactionreport",
  AGENT_UTILITY: "/report/getallutilitytranactionreport",
  // AGENT_LOGIN: "/report/loginreports",
  AGENT_LOGIN: "/report/getallloginreports",
  AGENT_DOMANCY: "/report/domancyreport",
  USER_NAME: "/user",
  AGENT_TO_AGENT: "report/agenttoagenttransactionreport",
  AGENT_COMMITION_CALCULATION: "/report/commisioncalculation",
  // AGENT_BANKING_DISTRIBUTION: "report/agencybankingdistributionsreport",
  AGENT_BANKING_DISTRIBUTION: "report/agencybankingcommissiondistributionsreport",

  AGENT_BANKING_COMMITION: "report/agencybankingcommissionreport",
  GEPG_TRANSACTION: "report/gepgtransactionsreport",
  ADMINUNSUCCESSFUL_REPORT: "report/getallunsuccessfultransactionsreports",
  ADMINREVERSED_REPORT: "report/getallreversedtransactionsreport",
  AGENTKYCLIST: "kycagent/getagency",

  GETALLCORPORATEAGENCY: "kycagent/getallagency",



  // REVERSED_REPORT: "report/reversedtransactionsreport",
  REVERSED_REPORT: "report/getallreversedtransactionsreport",

  UNSUCCESSFUL_REPORT: "report/unsuccessfultransactionsreports",


  ACCOUNT_TRANSACTION_LIMIT: "/accounttransactionlimit",
  ACCOUNT_TRANSACTION_LIMIT_AUTHORIZE: "/accounttransactionlimit/authorise",
  ACCOUNT_TRANSACTION_LIST:'/accounttransactionlimit/filter',


  AGENTTOAGENT: "report/getallagenttoagenttransactionreports",
  AGENT_KYC: "/kycagent/agentkycdetails",
  KYC_DETAILS: "/kycagent/agent/",
  BILLER_TYPE: "/interface/billertype",
  BILLER_CATEGORY: "/interface/billercategory",
  Bill_Payment_Lookup: "/interface/billpaymenetlookup",
  AGENCY_DROPDOWN: "/kycagent/agencyid",
  FETCH_CBS_CIF_DEATILS: "/kycagent/fetchcbscustomerid",


  STATEMENT_LOOK_UP: "/interface/ministatement",


  TRANSACTION_COMMISSION: "/commission",
  TRANSACTION_COMMISSION_SUMMARY: "/commission",
  TRANSACTION_FILTER: "/commission/filter",
  COMMISSION_SLAB_TRANSACTIONTYPE: '/commission/transaction',

  AGENT_TO_AGENT: "report/agenttoagenttransactionreport",
  AGENCY_ID: "/kycagent/getagentsbyagency",

  BALANCE_ENQUIRY: "/interface/accountbalance",

  DIVICE_ALLOCATION: "/agentdeviceallocation",
  DIVICE_FILTER: "agentdeviceallocation/filter",
  DIVICE_AUTHORIZE: "agentdeviceallocation/authorise",
  CORPORATE_AGENT: "kycagent/corporateagent",

  Agent_By_Id: "kycagent/getagentsbyagency",

  UNSUCCESSFUL_TRANS_REPORT: "report/getallunsuccessfultransactionsreports",

  GETAGENTS: "kycagent/getagents",


  AGENT_REGISTRATION: "agentregistration/agents",
  GET_AGENCY: "kycagent/getagency",
  ALL_TRANSACTIONS: "bankreport/alltransaction",
  REGISTERED_REPORT: "bankreport/registerdagents",
  CASH_DEPOSITE: "bankreport/cashdeposit",
  CASH_WITHDRWAL: "bankreport/cashwithdrawal",
  BANK_UTILITY: "bankreport/utilitytranaction",
  REVERSE_TRANSACTION: "bankreport/reversedtransactions",
  LOGIN_REPORT: "bankreport/loginreports",
  COMMISSION_DISTRIBUTION: "bankreport/agencybankingcommissiondistributionsreport",

  Bank_UNSUCCESSFUL_TRANS_REPORT: "bankreport/unsuccessfultransactions",
  Bank_GePg: "bankreport/gepgtransactions",
  Bank_Agency_BANKING_COMMITION: "bankreport/agencybankingcommissionreport",

  TRANSACTION_ENABLE_DISABLE: "/transactionenabledisable",
  TRANSACTION_ENABLEDISABLE_FILTER: "transactionenabledisable/filter",
  TRANSACTION_ENABLEDISABLE_AUTHORIZE: "/transactionenabledisable/authorise",

  TRANSACTION_LIMITS: "/transactionlimit",
  TRANSCTION_LIMIT_FILTER: "/transactionlimit/filter",
  TRANSACTION_LIMIT_AUTHORIZE: "/transactionlimit/authorise",
  DEVICE_LISTS: '/agentdeviceallocation',
  AGENCY_LIMITS: "agencytransactionlimit/agencylimit",
  AGENCY_TRANSACTION_LIMIT: "/agencytransactionlimit",
  AGENCY_TRANSACTION_FILTER: "/agencytransactionlimit/filter",
  AGENCY_TRANSACTION_AUTHORIZE: "/agencytransactionlimit/authorise",
  UNUSED_POS_DEVICES: 'agentdeviceallocation/unusedposdevice',
  UNUSED_POS_DEVICES_MODIFY: 'agentdeviceallocation/unusedposdevicemodify',


  UPLOAD_DEVICE: 'agentdeviceallocation/uploaddevice',


  RETAIL_AGENT: "/retailagentdevicemapping",
  RETAIL_AGENT_FILTER: "retailagentdevicemapping/filter",
  RETAIL_AGENT_AUTHORIZE: "retailagentdevicemapping/authorise",
  RETAIL_AGENT_REJECT: "/retailagentdevicemapping/reject",


  CORPORATE_AGENT_LIMIT: "agenttransactionlimit",
  CORPORATE_AGENT_LIMIT_LIST: "agenttransactionlimit/filter",
  CORPORATE_AGENT_LIMIT_AUTH: "agenttransactionlimit/authorise",

  RETAIL_AGENT_TRANSACTION_LIMIT: "/retailagenttransactionlimit",
  RETAIL_AGENT_TRANS_LIMIT_FILTER: "/retailagenttransactionlimit/filter",
  RETAIL_AGENT_TRANS_LIMIT_AUTHORIZE: "/retailagenttransactionlimit/authorise",


  //Agency agent outlet location
  AGENCY_AGENT_OUTLET_LOCATION: "/agencyagentoutletlocation",
  AGENCY_AGENT_OUTLET_LOCATION_FILTER: "/agencyagentoutletlocation/filter",
  AGENCY_AGENT_OUTLET_LOCATION_AUTHORIZE: "/agencyagentoutletlocation/authorise",
  AGENCY_AGENT_OUTLET_LOCATION_REJECT: "/agencyagentoutletlocation/reject",


  AGENCY_BY_BRANCHID:"/kycagent/getagency",
  AGENT_BY_BRANCHID:"agentregistration/agents",

  ACCOUNT_TRANSACTION_LIMIT: "/accounttransactionlimit",
ACCOUNT_TRANSACTION_LIMIT_AUTHORIZE: "/accounttransactionlimit/authorise",


//Retail agent withdrwal limit 

RETAIL_AGENT_WITHDRAWAL_LIMIT_FIND: "/retailagentcashwithrallimit/filter",
RETAIL_AGENT_WITHDRAWAL_LIMIT_OPERATION: "/retailagentcashwithrallimit",
RETAIL_AGENT_WITHDRAWAL_LIMIT_AUTHORISE: "/retailagentcashwithrallimit/authorise",


//Retail agent withdrwal limit 

RETAIL_AGENT_TRANSACTION_ENABLE_DISABLE_FIND: "/retailagenttransactionenabledisable/filter",
RETAIL_AGENTT_RANSACTION_ENABLE_DISABLE_OPERATION: "/retailagenttransactionenabledisable",
RETAIL_AGENT_TRANSACTION_ENABLE_DISABLE_AUTHORISE: "/retailagenttransactionenabledisable/authorise",

//Dashbaord
ALL_COUNTS:'/dashboard/getallcounts',
REGISTERED_AGENT:'/dashboard/registeredagentfilter',
ACTIVE_AGENT_FILTER:'/dashboard/activefilter',
INACTIVE_AGENT_FILTER:'/dashboard/inactivefilter',
ACTVE_INACTIVE_AGENT:'/dashboard/activeinactiveagent',
ONBOARD_AGENT:'/dashboard/onboaredagentdetails',
BLOKCED_USER:'/dashboard/blokceduserfilter',
DISABLED_AGENT_FILTER:'/dashboard/disabledagent',


//ALL AGENT 
ALL_AGENT_LIST:"/kycagent/getallagentdetails",
//ALL CUSTOMER ACCOUNT FOR SAME CIF FROM CBS 
ALL_CUSTOMER_ACCOUNT_LIST:"/interface/getcustomerallaccount",


//COMMISSION 
COMMISSION_FILTER: "/commissions/filter",
COMMISSION_OPERATION: "/commissions",
COMMISSION_AUTHORIZE: "/commissions/authorise",
COMMISSION_BY_TRANSACTIONTYPE: "/commissions/getbytransactiontype",
COMMISSION_REMOVE: "/commissions/remove",




//GET PENDING AGENT LIST 
PENDING_AGENT_LIST : "/agentregistration/pendingagents",

//Bulk commission Post
FETCH_COMMISSION_DETAILS: "/interface/fetchcommissiondetails",
COMMISSION_CREATE: "/interface/createcommission",
FETCH_BATCHWISE_DATA: "/interface/fecthbatchwisedetails",
FETCH_DETAIL_FOR_AUTHORIZATION: "/interface/fecthauthrizationdetails",




BULK_COMMISSION_POST: "/interface/bulkcommissionposting",

COMMISSION_POSTING_REPORT: "/bankreport/commissionpostingreport",
COMMISSION_POSTING_DETAILS: "/bankreport/commissiondetails",

VERIFY_BATCH_NUMBER: "/interface/verifybatchnumber",
RECALCULATE_COMMISSION: "/interface/recalculatecommission",


AGENT_CBS_ACCOUNT_LIST: "/kycagent/getagentcbsaccountlist",

//Agent Activity Service 
GET_AGENT_LIST_FOR_RESET_PIN:"/kycagent/getallagentdetails",
GETHINTQUESTION: "/dropdown",

AGENCY_ADMIN_FILTER: "/agency/filter",

AGENCY_ALL_AGENT_LIST:"/agency/getagentsbyagency",

DEVICE_BATCH_COUNT: "/agentdeviceallocation/batchcount",
DEVICE_BATCH_LIST: "/agentdeviceallocation/devicebatchdata",
DEVICE_AUTHORISE_BATCH: "/agentdeviceallocation/authorisebatch",











};
