import http from "./http.service";
import config from "./constant";


const getMasterDataList = async (data) => {
    try {
        const reqParams = {
            ...data,
        };
        const response = await http.post(config.MASSTERDATA, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const getHintquestionListList = async (data) => {
    try {
        const reqParams = {
            ...data,
        };
        const response = await http.post(config.GETHINTQUESTION, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};
const MasterdataService = {
 
    getMasterDataList,
    getHintquestionListList

};

export default MasterdataService;
