export const NAVIGATION_LINKS = {
  ROLE: "/role",
  USER: "/user",
  "TERMS_&_CONDITIONS": "/tc",
  LOCATIONS: "/location",
  NEW_REGISTRATION: "/customer",
  ENQUIRY: "/enquiry",
  FAQS: "/faq",
  SECURITY_TIPS: "/security",
  APPLICATION_PARAMETERS: "/parameter",
  REQUEST: "/request",
  APPROVALS: "/approvals",
  PRIVACY_POLICY: "/privacy",
  TRANSACTION_RULE: "/rule",
  TRANSACTION_LIMIT: "/limit",
  STOP_CHEQUE_REASON: "/stop-cheque",
  REPORTS: "/reports",
  TERM_DEPOSIT: "/term-deposit",
  CARD_CODES: "/card-code",
  REMARKS: "/remarks",
  CORPORATE_GROUP: "/corporate-group",
  KYC_DETAILS: "/kycDetails",
  NOTIFICATIONS: "/notifications",
  STATIC_FIELDS: "/static-fields",
  CHEQUE_BOOK_REQUEST: "/chequebook-request",
  DEBIT_CARD_REQUEST: "/debitcard-request",
  TRANSACTIONS_INQUIRY: "/transactions-inquiry",
  CANCEL_SCHEDULED_TRANSACTION: "/scheduled-transaction",
  BRANCH: "/branch",
  BRANCH_IFSC: "/branch-ifsc",
};

export const LANGUAGES = [
  { value: "en", label: "English" },
  { value: "hi", label: "Hindi" },
  { value: "ka", label: "Kannada" },
  { value: "mr", label: "Marathi" },
];

export const TC_MODULES = [
  { value: "REGISTRATION", label: "Registration" },
  { value: "BENEFICIARY", label: "Beneficiary" },
  { value: "FUNDTRANSFER", label: "Fund Transfer" },
  { value: "MPINPASSWORDRESET", label: "Reset MPIN" },
  { value: "TOUCH_ID", label: "Touch Id" },
  { value: "SECURITY_TIPS", label: "Security Tips" },
  { value: "SAFTEY_TIPS", label: "Safety Tips" },
  { value: "GENERAL", label: "General" },
];

export const TRANSACTION_TYPES = [
  { value: "RTGS", label: "Real Time Transfer" },
  { value: "IAT", label: "Internal Account Transfer" },
  { value: "OAT", label: "Own Account Trasfer" },
  { value: "SWIFT", label: "International Account Transfer" },
  { value: "IMPS", label: "Immediate Payment Service" },
  { value: "NEFT", label: "National Electronic Funds Transfer" },
];

export const OPERATIONS = {
  1: "-R---",
  2: "C----",
  3: "CR---",
  4: "--U--",
  5: "-RU--",
  6: "C-U--",
  7: "CRU--",
  8: "---D-",
  9: "-R-D-",
  10: "C--D-",
  11: "CR-D-",
  12: "--UD-",
  13: "-RUD-",
  14: "C-UD-",
  15: "CRUD-",
  16: "----A",
  17: "-R--A",
  18: "C---A",
  19: "CR--A",
  20: "--U-A",
  21: "-RU-A",
  22: "C-U-A",
  23: "CRU-A",
  24: "---DA",
  25: "-R-DA",
  26: "C--DA",
  27: "CR-DA",
  28: "--UDA",
  29: "-RUDA",
  30: "C-UDA",
  31: "CRUDA",
};
export const Screens = {
  ROLE: 2048,
  ROLE_SCREEN: 2042,
  SCREEN: 2047,
  VILLAGE: 2070,
  STATE: 2066,
  BRANCH: 86,
  BANK: 1510,
  DISTRICT: 2064,
  TALUKA: 2068,
  COLLECTION: 2074,
  ALERT: 2085,
  BCCATEGORY: 2072,
  BIOMETRIC: 2087,
  USER: 3,
  AGENT: 2076,
  BANK_PARAM: 2083,
  ACCOUNT_PARAM: 2080,
  ACCOUNT_TYPE: 2097,
  FDRD: 2089,
  RECURRINGDEPOSIT: 2095,
  FILEEXTENSION: 2091,
  FILEUPLOAD: 2093,
  //AGENTLOCATIONMAPPING: 2103,
  BCAGENTLOCATION: 2101,
  CURRENTACCOUNT: 2109,
  AGENTACCOUNT: 2105,
  AGENTENABLEDISABLED: 2133,
  CORPORATEAGENT: 2137,
  RECURRINGACCOUNT: 2141,
  LoanLead: 2145,
  RECURRINGDEPOSITEINST: 2143,
  AGENTLOCATIONMAPPING: 2103,
  AUTHORISATIONMAINTENECE: 1000,
  AUTHORISATIONTRANSACTION: 1001,
  SAVINGACCOUNT: 2107,
  AUTHORISATIONMAINTENECE: 1000,
  AUTHORISATIONTRANSACTION: 1001,
  USERBRANCH: 2050,
  INTERFACE: 2147,
  MENU: 2148,
  CORPORATECUSTOMER: 7,
  kycagent: 2168,
  CATEGORYMAP: 2151,
  DEVICEALLOCATION: 2157,
  CORPORATEAGENT: 2198,
  TRANSACTIONENABLEDISABLE: 2155,
  AGENCYTRANSACTION: 2230,
  AGENCYAGENTOUTLETLOCATION: 2101,
  AGENTOUTLETLOCATION: 2234,

  ACCOUNTTRANSACTIONLIMIT: 2239,

  RETAILAGENTDEVICEMAPPING: 2236,
  CORPORATEAGENTLIMIT: 2241,
  ACCOUNTTRANSACTIONLIMIT: 2239,
  AGENTTRANSACTIONLIMIT: 2243,
  COMMISSIONSLAB: 2189,
  RETAIL_AGENT_WITHDRWAL_LIMIT: 2246,
  RETAIL_AGENT_TRANSACTION_ENABLE_DISABLE: 2248,
  ACTIVE_AGENT: 2251,
  INACTIVE_AGENT: 2252,

  COMMISSION_SLAB: 2189,

  USER_PASSWORD_RESET: 2257,
  RETAIL_AGENT_REGISTARTION_LIST: 2232,
  POST_COMMISSION: 2254,
  SUPPORT: 2258,
  VIEW_LOCATION_LIST: 2260,






};
