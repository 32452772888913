import http from "./http.service";
import config from "./constant";

import * as CryptoJS from "crypto-js";
import environment from "../environment";
import { store } from "../store";
import { SET_AUTH_TOKEN, SET_LOGIN_DETAILS, SET_USER_LIST,SET_DEVICE_ID,SET_SERVER_KEY,SET_SHARED_KEY } from "../store/constants";
import _sodium from "libsodium-wrappers";
import base64 from "base-64";
import { v4 as uuidv4 } from "uuid";
const AES_SECRET_KEY = CryptoJS.enc.Utf8.parse(environment.ENCRYPT_KEY);
export const encryptAES = (text) =>
  CryptoJS.AES.encrypt(text, AES_SECRET_KEY, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();

const postLogin = async (data) => {
  //alert(JSON.stringify(data))
  try {
    const reqParams = {
      ...data,
      logoutOtherSession:
        data.logoutOtherSession != undefined ? data.logoutOtherSession : false,
      password: data.password,
      // requestId: "LOGIN",
      // module: "LOGIN",
      // channelType: "ADMIN",
      // ipAddress: "0.0.0.0",
    };
    // localStorage.setItem("Password", reqParams["password"]);
    const response = await http.post(config.LOGIN, reqParams);
    store.dispatch({ type: SET_LOGIN_DETAILS, payload: response.data });
    store.dispatch({
      type: SET_AUTH_TOKEN,
      payload: response.data.token,
    });
    return { status: response.status, data: response };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getMenus = async (data) => {
  try {
    const reqParams = { ...data };
    const response = await http.post(config.GET_MENUS, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllParentMenus = async (data) => {
  try {
    const reqParams = {
      ...data,
    };
    const response = await http.post(config.GET_PARENT_MENUS, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getBranchSettings = async () => {
  try {
    const reqParams = {};
    const response = await http.get(config.BRANCH_SETTING, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllCounts = async () => {
  try {
    const reqParams = {};
    const response = await http.get(config.ALL_COUNTS, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const getAllMenus = async () => {
  try {
    const reqParams = {};
    const response = await http.get(config.ALL_MENUS, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const postLogout = async (data) => {
  try {
    const reqParams = {
      ...data,
    };
    const response = await http.post(config.LOGOUT, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const resetPassword = async (data) => {
  try {
    const { app } = store.getState();
    const reqParams = {
      ...data,
      sessionUserId: app?.loginDetails.userId,
      sessionVersionNo: app?.loginDetails.versionNo,
    };
    const response = await http.post(
      config.USER_FORCE_CHANGE_PASSWORD,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const changePasswordVerify = async (data) => {
  try {
    const { app } = store.getState();
    const reqParams = {
      module: "CHANGEPASSWORD",
      requestId: "CHANGEPASSWORD",
      userName: app?.loginDetails.userName,
      sessionVersionNo: app?.loginDetails.versionNo,
      sessionUserId: app?.loginDetails.userId,
      channelType: "ADMIN",
      ipAddress: "0.0.0.0",
    };
    const response = await http.post(config.CHANGE_PASSWORD_VERIFY, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const changePassword = async (data) => {
  try {
    const { app } = store.getState();
    // const oldPassword = encryptAES(data.oldPassword)
    // const newPassword = encryptAES(data.newPassword)
    const reqParams = {
      otp: data.otp,
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
      userName: app?.loginDetails.user.userName,
    };
    const response = await http.put(config.CHANGE_PASSWORD, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};



const userUnlockAccount = async (data) => {
  try {
    const { app } = store.getState();
    const reqParams = {
      otp: data.otp,
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
      userName: data.userName,
    };
    const response = await http.put(config.USER_UNLOCK_ACCOUNT, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const headerlogin = async (data) => {
  const password = localStorage.getItem("Password");
  try {
    const reqParams = {
      ...data, password
    };
    const response = await http.post(config.LOGIN, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const WebportalLogin = async (data) => {
  //alert(JSON.stringify(data))
  try {
    const reqParams = {
      ...data,
      logoutOtherSession:
        data.logoutOtherSession != undefined ? data.logoutOtherSession : false,
      password: data.password,
     
    };
    // localStorage.setItem("Password", reqParams["password"]);
    const response = await http.post(config.WEBPORTAL, reqParams);
    store.dispatch({ type: SET_LOGIN_DETAILS, payload: response.data });
    store.dispatch({
      type: SET_AUTH_TOKEN,
      payload: response.data.token,
    });
    return { status: response.status, data: response };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllUserList = async () => {
  try {
    const response = await http.get(config.ALL_USER_LIST);
    store.dispatch({ type: SET_USER_LIST, payload: response.data });

    return { status: response.status, data: response.data };

  } catch (err) {
    return Promise.reject(err);
  }
};




const generateKey = async (data) => {
  try {

    let uniqueId = await uuidv4();
    console.log("---uuid---"+uniqueId);

    store.dispatch({ type: SET_DEVICE_ID, payload: uniqueId });
    await _sodium.ready;
    const Sodium = _sodium;
    const clientKeyPair = await Sodium.crypto_box_keypair();

    const reqParams = {
      clientId: base64.encode(uniqueId),
      clientKey: Sodium.to_base64(
        clientKeyPair?.publicKey,
        Sodium.base64_variants.ORIGINAL
      ),
      encryptionNotReqd: true,
      requestId: "META_DATA",
      language: "en",
      channelType: "INTERNET",
      ipAddress: "0.0.0.0",
    };

    console.log("---reqParams---"+JSON.stringify(reqParams));


    const response = await http.post(
      config.GERENERATEKEY,
      reqParams
    );
    if (response.status === 200) {
      store.dispatch({
        type: SET_SERVER_KEY,
        payload: response.data.serverKey,
      });

      const sharedKey = await Sodium.crypto_box_beforenm(
        Sodium.from_base64(
          response.data.serverKey,
          Sodium.base64_variants.ORIGINAL
        ),
        clientKeyPair.privateKey,
        "uint8array"
      );

       // Convert shared key to Base64
       const sharedKeyBase64 = Sodium.to_hex(sharedKey, Sodium.base64_variants.ORIGINAL);

       
      store.dispatch({ type: SET_SHARED_KEY, payload: sharedKey });

      return {
        status: response.status,
        data: { ...response.data, sharedKey: sharedKey, uniqueId: uniqueId },
      };
   }

  }
  catch (err) {
    return Promise.reject(err);
  }

};


const forgotPasswordValidateUser = async (data) => {
  try {
    const reqParams = { ...data };
    const response = await http.post(config.FORGOT_PASSWORD_VALIDATE_USER, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const forgotPassword = async (data) => {
  try {
    const reqParams = {
      ...data
    };
    const response = await http.put(config.FORGOT_PASSWORD, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};



const AuthService = {
  postLogin,
  headerlogin,
  getBranchSettings,
  getAllParentMenus,
  getAllCounts,
  getAllMenus,
  changePasswordVerify,
  getMenus,
  postLogout,
  resetPassword,
  changePassword,
  forgotPassword,
  userUnlockAccount,
  WebportalLogin,
  getAllUserList,
  generateKey,
  forgotPasswordValidateUser
};

export default AuthService;
