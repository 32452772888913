import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { permissionListSelector } from "../../store/selectors/app.selector";
import {
  MdSupervisorAccount,
  MdDashboard,
  MdMenu,
  MdManageAccounts,
  MdPermIdentity,
} from "react-icons/md";
import menu from "./menu.json";
import { Button, Menu, Popover } from "antd";

const { SubMenu } = Menu;

export default function NavBar({ onOpenChange, openKeys, menuList, totalMenuList }) {
  const location = useLocation();
  const [groupingMenuList, setGroupingMenuList] = useState([...menuList]);
  const root = "/" + location.pathname.split("/")[1];
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <div className=""
    style={{
      background: "#044c74",
      
    }}
    
    >
      {/* <button
        onClick={toggleSidebar}
        className={`sidebar-button ${sidebarVisible ? "" : "closed"}`}
      >
        <MdMenu className="icon-style" />
      </button> */}
      {sidebarVisible && (
        <div 
        // className=" sidebar sidebar-content"
         >
          <NavLink
            to="/dashboard"
            className="menu-item-parent"
          >
            <MdDashboard className="icon-style" />
            <div className="menu-name">Dashboard</div>
          </NavLink>
          {menuList.map((option, idx) => (
            <React.Fragment key={idx}>
              {option["subMenus"] && option["subMenus"].length > 0 ? (
                <Menu
                  mode="inline"
                  style={{
                    background: "#044c74",
                    color: "#fff",
                    borderBottom: "1px solid rgb(92, 90, 90)",
                    borderRight: "0px",
                  }}
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                  defaultOpenKeys={["0"]}
                  selectedKeys={[root, location.pathname]}
                >
                  <SubMenu
                    key={(idx + 1)}
                    title={<span className="menu-name1" style={{whiteSpace:'pre-wrap'}}>{option.menuName}</span>}
                  >
                    {option["subMenus"].map((menu, idx) => (
                      <Menu.Item key={menu.pathName}>
                        <NavLink to={menu.pathName.trim()} className={"menu-name1"}>
                          {menu.menuName}
                        </NavLink>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                </Menu>
              ) : (
                <NavLink
                  to={option.pathName.trim()}
                  className={
                    location?.pathname.includes(option.pathName.trim())
                      ? "menu-item-parent selected"
                      : "menu-item-parent"
                  }
                >
                  <div className="">{option.menuName}</div>
                </NavLink>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
}
