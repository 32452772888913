import Axios from "axios";
import { store } from "../store";
import {
  SET_AUTH_REFRESH_TOKEN,
  SET_AUTH_TOKEN,
  SET_ERROR_MESSAGE,
  SET_SERVICE_LOADER
} from "../store/constants";
import Config from "../environment";
import NotificationService from "./notification.service";
import _sodium from "libsodium-wrappers";
import base64 from "base-64";
import { Buffer } from "buffer";
import { json } from "react-router-dom";
let encryptionNotReqd = true;

const axios = Axios.create({
  baseURL: Config.SERVICE_URL,
});

axios.interceptors.request.use(
  async (config) => {
    const { auth, app } = store.getState();
    const interceptedConfig = config;

    
    if (auth.authToken !== null) {
      interceptedConfig.headers.Authorization = `Bearer ${auth.authToken}`;
    }
    store.dispatch({
      type: SET_SERVICE_LOADER,
      payload: true,
    });
    if (Config.ENCRYPT_ENABLE && interceptedConfig.method !== "get" && interceptedConfig.method !== "delete") {
      if (
        interceptedConfig.data &&
        interceptedConfig.data.encryptionNotReqd === true) {
        encryptionNotReqd = true;
        return interceptedConfig;
      } 
      if(interceptedConfig.url.endsWith("agentdeviceallocation/uploaddevice")){
        encryptionNotReqd = true;
        return interceptedConfig;
      }
      
      else {
        try {
          await _sodium.ready;
          const Sodium = _sodium;
          encryptionNotReqd = false;
          const nonce = await Sodium.randombytes_buf(24);
          let msg = JSON.stringify(interceptedConfig.data);
          const cipherText = await Sodium.crypto_box_easy_afternm(
            msg,
            nonce,
            app?.sharedKey,
            "uint8array"
          );
          const buffer = Buffer.from(cipherText, "base64");
          const bufString = buffer.toString("hex");
          console.log(interceptedConfig);
          interceptedConfig.data = {
            message: bufString,
            clientId: base64.encode(app?.deviceId),
            nonce: Sodium.to_base64(nonce, Sodium.base64_variants.ORIGINAL),
          };
          return interceptedConfig;
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      return interceptedConfig;
    }
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  async (response) => {
    try {
      const { app } = store.getState();

      store.dispatch({
        type: SET_SERVICE_LOADER,
        payload: false,
      });

      if (response.headers?.access_token) {
        store.dispatch({
          type: SET_AUTH_TOKEN,
          payload: response.headers.access_token,
        });
      }
      if (response.headers?.refresh_token) {
        store.dispatch({
          type: SET_AUTH_REFRESH_TOKEN,
          payload: response.headers.refresh_token,
        });
      }

      if (Config.ENCRYPT_ENABLE && response.config.method !== "get" && response.config.method !== "delete") {
        if (encryptionNotReqd) {
          return response;
        } else {
          console.log("---Encrypted response--" + JSON.stringify(response.data));
          await _sodium.ready;
          const Sodium = _sodium;
          const uint8arrayText = await Sodium.crypto_box_open_easy_afternm(
            Sodium.from_hex(response.data.Message),
            Sodium.from_base64(
              response.data.Nonce,
              Sodium.base64_variants.ORIGINAL
            ),
            app.sharedKey,
            "uint8array"
          );

          console.log("uint8arrayText------" + uint8arrayText);
          const plainText = Buffer.from(uint8arrayText, "hex").toString(
            "base64"
          );
          console.log("plainText------" + base64.decode(plainText));
          console.log("Request", JSON.parse(base64.decode(plainText)));
          return {
            status: response.status,
            data: JSON.parse(base64.decode(plainText)),
          };
        }
      } else {
        return response;
      }
    } catch (error) {
      console.error("Error in response interceptor:", error);
      return Promise.reject(error);
    }
  },
  async (error) => {
    try {
      store.dispatch({
        type: SET_SERVICE_LOADER,
        payload: false,
      });

      let errorObj = {};
      const originalRequest = error.config;
      if (error.response) {
        if (Config.ENCRYPT_ENABLE && error.config.method !== "get") {
          const { app } = store.getState();
          const hasKey = "Nonce" in error.response.data;
          if (hasKey) {
            console.log("---------")
            await _sodium.ready;
            const Sodium = _sodium;
            const uint8arrayText = await Sodium.crypto_box_open_easy_afternm(
              Sodium.from_hex(error.response.data.Message),
              Sodium.from_base64(
                error.response.data.Nonce,
                Sodium.base64_variants.ORIGINAL
              ),
              app.sharedKey,
              "uint8array"
            );

            const errorText = Buffer.from(uint8arrayText, "hex").toString(
              "base64"
            );
            console.log(JSON.parse(base64.decode(errorText)));

            errorObj = {
              status: error.response.status,
              data: JSON.parse(base64.decode(errorText)),
            };
            if (errorObj.status === 500) {
              if (errorObj.data.message == "The user who create cannot authorize it") {
                NotificationService.showErrorMessage(
                  "The user who create cannot authorize it"
                );
              }
              return;
            }      
            let configData = JSON.parse(error.config.data);
            const uint8arrayReqData = await Sodium.crypto_box_open_easy_afternm(
              Sodium.from_hex(configData.message),
              Sodium.from_base64(
                configData.nonce,
                Sodium.base64_variants.ORIGINAL
              ),
              app.sharedKey,
              "uint8array"
            );

            const errorConfigDataText = Buffer.from(
              uint8arrayReqData,
              "hex"
            ).toString("base64");

            console.log(JSON.parse(base64.decode(errorConfigDataText)));
            if (
              JSON.parse(base64.decode(errorConfigDataText)).toString() !==
              "[object FormData]"
            ) {
              originalRequest.data = JSON.parse(
                base64.decode(errorConfigDataText)
              );
            }
            
          }
          else {
            errorObj = {
              status: error.response.status,
              data: error.response.data,
            };
            if (error.config.data.toString() !== "[object FormData]") {
              originalRequest.data = JSON.parse(error.config.data);
            }
          }
        } else {
          errorObj = {
            status: error.response.status,
            data: error.response.data,
          };
          if (error.config.data.toString() !== "[object FormData]") {
            originalRequest.data = JSON.parse(error.config.data);
          }
        }

        if (errorObj.status === 403) {
          store.dispatch({ type: "USER_LOGOUT" });
          store.dispatch({
            type: SET_ERROR_MESSAGE,
            payload: {
              ...errorObj,
              message: "Session Expired. Please try to login again",
            },
          });
          return;
        }
        if (errorObj.status === 401) {
          store.dispatch({ type: "USER_LOGOUT" });
          store.dispatch({
            type: SET_ERROR_MESSAGE,
            payload: {
              ...errorObj,
              message: "Session Expired. Please try to login again",
            },
          });
          return;
        } 
        else if (errorObj.status === 500) {
          if (error.response.data.message == "The user who create cannot authorize it") {
            NotificationService.showErrorMessage(
              "The user who create cannot authorize it"
            );
          }
        }     
        else if (error.response.status === 500) {
          if (error.response.data.message == "The user who create cannot authorize it") {
            NotificationService.showErrorMessage(
              "The user who create cannot authorize it"
            );
          }
        }     
        else {
          store.dispatch({
            type: SET_ERROR_MESSAGE,
            payload: errorObj,
          });
        }
      } else {
        store.dispatch({ type: "USER_LOGOUT" });
        store.dispatch({
          type: SET_ERROR_MESSAGE,
          payload: {
            code: "Network Error",
            message: "The server is not reachable. Please try after some time.",
            errors: null,
          },
        });
      }
      return Promise.reject(errorObj || error.request || error.message);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default axios;
