import React from "react";
import { Form, Input, Button, Select } from "antd";
import { Outlet } from "react-router";
import "./auth.css";
import LOGO from "../../assets/images/azania1.jpg";
import LOGO1 from "../../assets/images/azania.jpg";
import { Typography } from '@material-ui/core';

const { Option } = Select;

// const LANGUAGES = [
//   { label: "English", code: "en" },
//   { label: "Spanish", code: "es" },
//   { label: "Italian", code: "it" },
// ];

export default function AuthLayout({ children }) {
  const onFinish = (values) => {
    console.log("Received values:", values);
  
  };

  return (
    <div className="auth-container">
      <div className="image-container">
        <img src={LOGO1} style={{ height: '100%', width: '100%' }} alt="Logo" />
        <div className="text-on-image">
          <p>Welcome To Agency Banking</p>
        </div>
      </div>

      <div className="auth-render-section">
        {/* <div style={{ marginLeft: "" }}>
          <Select className="language-select" defaultValue={"es"}>
            {LANGUAGES.map(({ code, label }) => (
              <Option key={code} value={code}>
                {label}
              </Option>
            ))}
          </Select>
        </div> */}

        <div className="flex flex-row justify-between">
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img src={LOGO} alt="Logo" style={{padding:'10px'}}/>
          </div>
        </div>

        <div className="">{children}</div>
        <Outlet />
      </div>
    </div>
  );
}
