import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Alert } from "antd";
import AuthLayout from "./authLayout";
import AuthService from "../../services/auth.service";
import NotificationService from "../../services/notification.service";

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const request = {
        newPassword: values.newPwd,
      };
      setLoading(true);
      const response = await AuthService.resetPassword(request);
      if (response.status === 200) {
        setLoading(false);
        NotificationService.showSuccessMessage(response.data["message"]);
        navigate("/login");
      } else {
        NotificationService.showErrorMessage(
          "Something went wrong. Please try after sometime."
        );
      }
    } catch (error) {
      setLoading(false);
      setMessage((error && error.data && error.data["message"]) || "");
    }
  };

  return (
    <AuthLayout>
      <h4>Force Change Password</h4>

      <div className="password-criteria" style={{margin:0}}>
        <h6>New Password Criteria</h6>
        <ul>
          <li>At least 1 lower case letter [a-z]</li>
          <li>At least 1 upper case letter [A-Z]</li>
          <li>At least 1 digit [0-9]</li>
          <li>At least 1 special character [@#&*!]</li>
          <li>Minimum 8 characters</li>
          <li>Maximum 20 characters</li>
        </ul>
      </div>

      <Form
        name="resetForm"
        layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
      >
        <Form.Item
          label="New Password"
          name="newPwd"
          rules={[
            {
              required: true,
              pattern: new RegExp(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#&*!])[A-Za-z\d@#&*!]{8,}$/
              ),
              message: "New Password should match Password Criteria!",
            },
          ]}
        >
          <Input.Password maxLength={20} size="large" placeholder="New Password" />
        </Form.Item>

        <Form.Item
          label="Confirm New Password"
          name="cNewPwd"
          rules={[
            {
              required: true,
              message: "Confirm New Password is required",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("newPwd") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords do not match!");
              },
            }),
          ]}
        >
          <Input.Password maxLength={20} size="large" placeholder="Confirm New Password" />
        </Form.Item>

        <Button
          style={{ marginTop: 10 }}
          block
          size="large"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Force Password
        </Button>
      </Form>

      {message.length > 0 && (
        <Alert
          style={{ marginTop: 30 }}
          showIcon={true}
          message={message}
          type="error"
        />
      )}
    </AuthLayout>
  );
}

export default React.memo(ResetPassword);
