import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Input, Button, Alert,Select } from "antd";
import AuthLayout from "./authLayout";
import AuthService from "../../services/auth.service";
import NotificationService from "../../services/notification.service";
import { NumericFormat } from "react-number-format";
import { useEffect } from "react";
import { useCallback } from "react";
import MasterdataService from "../../services/masterdata.service";


export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [stage, setStage] = useState(1);
  const [otpEnabled, setOtpEnabled] = useState(false);
  const [hintQuestion, setHintQuestion] = useState([]);


  useEffect(() => {
    getMasterDataList();
  }, []);


  const getMasterDataList = useCallback(async () => {
    try {
      const reqParams = {
        dataList: ["HintQuestion"],
        parameterList: "",
      };
      const response = await MasterdataService.getHintquestionListList(reqParams);
      if (response.status === 200) {
        setHintQuestion(response.data[0]);
      }
    } catch (error) {
      setHintQuestion([]);
    }
  }, []);

  const renderForgotPasswordStage = () => {
    switch (stage) {
      case 1:
        return renderForm();
      case 2:
        return renderOtp();
      default:
        return renderForm();
    }
  };



  const onforgotPasswordValidateUser = async (values) => {
    try {
      const request = {
        userName: values.userName,
        hintQuestion: values.hintQuestion,
        hintAnswer: values.hintAnswer,
      };
      setLoading(true);
      const response = await AuthService.forgotPasswordValidateUser(request);
      if (response.status === 200) {
        setLoading(false);
        NotificationService.showSuccessMessage(response.data["message"]);
        setStage(2);
        setMessage("");
      }
    } catch (error) {
      setLoading(false);
      setMessage((error && error.data && error.data["message"]) || "");
    }
  };



  const onforgotPassword = async (values) => {
    try {
      const request = {
        userName: values.userName,
        newPassword: values.newPassword,
      };
      setLoading(true);
      const response = await AuthService.forgotPassword(request);
      if (response.status === 200) {
        setLoading(false);
        NotificationService.showSuccessMessage(response.data["message"]);
        navigate("/login");
      }
    } catch (error) {
      setLoading(false);
    //  setMessage((error && error.data && error.data["message"]) || "");
      NotificationService.showErrorMessage(error.data.message);

    }
  };




 
  function renderForm() {
    return (
      <Form
        name="resetForm"
        layout="vertical"
        requiredMark={true}
        onFinish={onforgotPasswordValidateUser}
      >
        <Form.Item
          label="Username"
          name="userName"
          rules={[
            {
              required: true,
              message: "Username is required",
            },
          ]}
        >
          <Input size="large" placeholder="Username" />
        </Form.Item>

        <Form.Item
        label="Hint Question"
        name="hintQuestion"
        rules={[
          {
            required: true,
            message: "Hint Question is required",
          },
        ]}
      >
         <Select placeholder="Hint Question">
                      {hintQuestion.map((option, idx) => (
                        <Select.Option key={idx} value={option.name}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
      </Form.Item>

      <Form.Item
        label="Hint Answer"
        name="hintAnswer"
        rules={[
          {
            required: true,
            message: "Hint Answer is required",
          },
        ]}
      >
        <Input size="large" placeholder="Hint Answer" />
      </Form.Item>

        <Button
          style={{ marginTop: 10 }}
          block
          size="large"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Submit
        </Button>

        <Link hidden to={"/login"} className="mt-2">
          Return Login
        </Link>
      </Form>
    );
  }

  function renderOtp() {
    return (
      <Form
      name="resetForm"
      layout="vertical"
      requiredMark={true}
      onFinish={onforgotPassword}
    >
      <Form.Item
        label="Username"
        name="userName"
        rules={[
          {
            required: true,
            message: "Username is required",
          },
        ]}
      >
        <Input size="large" placeholder="Username" />
      </Form.Item>


      <Form.Item
        label="New Password"
        name="newPassword"
        rules={[
          {
            required: true,
            message: "New Password is required",
          },
        ]}
      >
        <Input.Password size="large" placeholder="New Password" />
      </Form.Item>

      <Form.Item
        label="Confirm New Password"
        name="confirmNewPassword"
        rules={[
          {
            required: true,
            message: "Confirm New is required",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("newPassword") === value) {
                return Promise.resolve();
              }
              return Promise.reject("Passwords do not match!");
            },
          }),
        ]}
      >
        <Input.Password size="large" placeholder="Hint Answer" />
      </Form.Item>
     

      <Button
        style={{ marginTop: 10 }}
        block
        size="large"
        type="primary"
        htmlType="submit"
        loading={loading}
      >
        Submit
      </Button>

      <Link to={"/login"} className="mt-2">
        Return Login
      </Link>
    </Form>
    );
  }

  return (
    <AuthLayout>
      <h4>Forgot password </h4>
      {renderForgotPasswordStage()}
      {message.length > 0 && (
        <Alert
          style={{ marginTop: 30 }}
          showIcon={true}
          message={message}
          type="error"
        />
      )}
    </AuthLayout>
  );
}
