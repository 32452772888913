import React, { Suspense } from "react";
import { Routes, Route, HashRouter, Navigate } from "react-router-dom";
import Loader from "./components/loader";
import Layout from "./pages/layout/layout";
import LoginComponent from "./pages/preAuth/login";
import ResetPassword from "./pages/preAuth/resetPassword";
import ForgotPassword from "./pages/preAuth/forgotPassword";

const Dashboard = React.lazy(() => import("./pages/dashboard/dashboard"));
const DashboardTotalAgentRegistered = React.lazy(() => import("./pages/dashboard/toatl-registred-agent"));
const DashboardActiveAgents = React.lazy(() => import("./pages/dashboard/active-agent-list"));
const DashboardInactiveAgents = React.lazy(() => import("./pages/dashboard/inactive-agent-list"));
const DashboardFailedRegisteredAgents = React.lazy(() => import("./pages/dashboard/failed-registred-agent"));
const DashboardBlockedUnBlockedAgents = React.lazy(() => import("./pages/dashboard/activate-deactivate-agent"));
const PendingForAuthorisation = React.lazy(() => import("./pages/dashboard/pending-authorisation"));


const RolesComponent = React.lazy(() => import("./pages/role/role"));
const RoleCreate = React.lazy(() => import("./pages/role/role-create"));
const RoleModify = React.lazy(() => import("./pages/role/role-modify"));

const UserComponent = React.lazy(() =>
  import("./pages/core-setup/users/users")
);
const UserCreate = React.lazy(() =>
  import("./pages/core-setup/users/user-create")
);
const UserModify = React.lazy(() =>
  import("./pages/core-setup/users/user-modify")
);
const UserEnableDisable = React.lazy(() =>
  import("./pages/core-setup/users/user-enable-disable")
);

const FundScreen = React.lazy(() =>
  import("./pages/Interface/cashtransactions")
);

// const FundtransferDetails = React.lazy(() =>
//   import("./pages/fund-transfer/fundtransfer-details")
// );






const UserBranchComponent = React.lazy(() =>
  import("./pages/core-setup/user-branch/user-branch")
);
const UserBranchCreate = React.lazy(() =>
  import("./pages/core-setup/user-branch/user-branch-create")
);
const UserBranchModify = React.lazy(() =>
  import("./pages/core-setup/user-branch/user-branch-modify")
);

const ScreenComponent = React.lazy(() =>
  import("./pages/core-setup/screen/screen")
);
const ScreenCreate = React.lazy(() =>
  import("./pages/core-setup/screen/screen-create")
);
const ScreenModify = React.lazy(() =>
  import("./pages/core-setup/screen/screen-modify")
);

const RoleScreenComponent = React.lazy(() =>
  import("./pages/core-setup/role-screen/role-screen")
);
const RoleScreenCreate = React.lazy(() =>
  import("./pages/core-setup/role-screen/role-screen-create")
);
const RoleScreenModify = React.lazy(() =>
  import("./pages/core-setup/role-screen/role-screen-modify")
);
const DistrictComponent = React.lazy(() => import("./pages/district/district"));

const DistrictCreate = React.lazy(() =>
  import("./pages/district/district-create")
);
const DistrictModify = React.lazy(() =>
  import("./pages/district/district-modify")
);
const TalukComponent = React.lazy(() => import("./pages/taluk/taluk"));

const TalukCreate = React.lazy(() => import("./pages/taluk/taluk-create"));
const TalukModify = React.lazy(() => import("./pages/taluk/taluk-modify"));

const VillageComponent = React.lazy(() =>
  import("./pages/village/vilage-list")
);

const VillageScreenCreate = React.lazy(() =>
  import("./pages/village/village-create")
);
const VillageScreenModify = React.lazy(() =>
  import("./pages/village/village-modify")
);
const FDRDComponent = React.lazy(() =>
  import("./pages/fdrd/fdrd")
);

const FDRDCreate = React.lazy(() =>
  import("./pages/fdrd/fdrd-create")
);
const FDRDModify = React.lazy(() =>
  import("./pages/fdrd/fdrd-modify")
);
const RecurringDepositComponent = React.lazy(() =>
  import("./pages/recurring-deposit/recurring-deposit")
);

const RecurringDepositCreate = React.lazy(() =>
  import("./pages/recurring-deposit/recurring-deposit-create")
);
const RecurringDepositModify = React.lazy(() =>
  import("./pages/recurring-deposit/recurring-deposit-modify")
);

const FileExtensionComponent = React.lazy(() =>
  import("./pages/file-extension/file-extension")
);

const FileExtensionCreate = React.lazy(() =>
  import("./pages/file-extension/file-extension-create")
);
const FileExtensionModify = React.lazy(() =>
  import("./pages/file-extension/file-extension-modify")
);

const FileUploadComponent = React.lazy(() =>
  import("./pages/file-upload/file-upload")
);

const FileUploadCreate = React.lazy(() =>
  import("./pages/file-upload/file-upload-create")
);
const FileUploadModify = React.lazy(() =>
  import("./pages/file-upload/file-upload-modify")
);

const AccountParameterComponent = React.lazy(() =>
  import("./pages/account-parameter/account-parameter-list")
);

const AccountParameterScreenCreate = React.lazy(() =>
  import("./pages/account-parameter/account-parameter-create")
);
const AccountParameterScreenModify = React.lazy(() =>
  import("./pages/account-parameter/account-parameter-modify")
);

const AccountTypeLimitComponent = React.lazy(() =>
  import("./pages/account-type-limit/account-type-limit-list")
);

const AccountTypeLimitScreenCreate = React.lazy(() =>
  import("./pages/account-type-limit/account-type-limit-create")
);
const AccountTypeLimitScreenModify = React.lazy(() =>
  import("./pages/account-type-limit/account-type-limit-modify")
);

const BankParameterComponent = React.lazy(() =>
  import("./pages/bank-parameter/bank-parameter-list")
);

const BankParameterScreenCreate = React.lazy(() =>
  import("./pages/bank-parameter/bank-parameter-create")
);
const BankParameterScreenModify = React.lazy(() =>
  import("./pages/bank-parameter/bank-parameter-modify")
);

const BiometricComponent = React.lazy(() =>
  import("./pages/biometric/biometric-list")
);

const BiometricScreenCreate = React.lazy(() =>
  import("./pages/biometric/biometric-create")
);
const BiometricScreenModify = React.lazy(() =>
  import("./pages/biometric/biometric-modify")
);

const CategoryScreenModify = React.lazy(() =>
  import("./pages/bc-category/category-modify")
);
const AlertScreenModify = React.lazy(() =>
  import("./pages/alert/alert-modify")
);

const AlertScreenCreate = React.lazy(() =>
  import("./pages/alert/alert-creater")
);

const AgentScreenCreate = React.lazy(() =>
  import("./pages/agent/agent-create")
);
const AgentScreenModify = React.lazy(() =>
  import("./pages/agent/agent-modify")
);

const CollectionScreenCreate = React.lazy(() =>
  import("./pages/collections/collection-creater")
);
const CollectionScreenModify = React.lazy(() =>
  import("./pages/collections/collection-modify")
);
const CategoryScreenCreate = React.lazy(() =>
  import("./pages/bc-category/category-creater")
);

const CollectionComponent = React.lazy(() =>
  import("./pages/collections/collection-list")
);
const CategoryComponent = React.lazy(() =>
  import("./pages/bc-category/category-list")
);
const AlertComponent = React.lazy(() => import("./pages/alert/alert-list"));
const AgentComponent = React.lazy(() => import("./pages/agent/agent-list"));

const StateComponent = React.lazy(() => import("./pages/state/state-list"));

const StateScreenCreate = React.lazy(() =>
  import("./pages/state/state-create")
);
const StateScreenModify = React.lazy(() =>
  import("./pages/state/state-modify")
);

const ChangePasswordComponent = React.lazy(() =>
  import("./pages/change-password/change-password")
);

const UserAccountUnlockComponent = React.lazy(() =>
  import("./pages/user-account-unlock/userAccountLock")
);

const BankComponent = React.lazy(() => import("./pages/bank/bank"));
const BankModifyComponent = React.lazy(() =>
  import("./pages/bank/bank-modify")
);


const Accountlimit = React.lazy(() =>
  import("./pages/account-transaction-limit/accounttransactionlimit"));

const AccountlimitModifyComponent = React.lazy(() =>
  import("./pages/account-transaction-limit/accounttransactionlimit-modify")
);

const AccountLimitCreate = React.lazy(() =>
  import("./pages/account-transaction-limit/accounttransactionlimit-create"))


const BranchComponent = React.lazy(() => import("./pages/branch/branch"));

const BranchCreateComponent = React.lazy(() =>
  import("./pages/branch/branch-create")
);

const BranchModifyComponent = React.lazy(() =>
  import("./pages/branch/branch-modify")
);

const ResetIBUserPasswordComponent = React.lazy(() =>
  import(
    "./pages/new-customer/request/reset-ib-user-password/reset-ib-user-password"
  )
);

const AgentmappingScreenCreate = React.lazy(() =>
  import("./pages/agentlocation-mapping/agentmapping-create")
);
const AgentmappingComponent = React.lazy(() =>
  import("./pages/agentlocation-mapping/agentmapping-list")
);

const AgentmappingModify = React.lazy(() =>
  import("./pages/agentlocation-mapping/agentmapping-modify")
);

const AgencyAgentOutletLocatiopnCreate = React.lazy(() =>
  import("./pages/agency-agent-outlet-location/agency-agent-outlet-location-create")
);
const AgencyAgentOutletLocationList = React.lazy(() =>
  import("./pages/agency-agent-outlet-location/agency-agent-outlet-location-list")
);
const AgencyAgentOutletLocationModify = React.lazy(() =>
  import("./pages/agency-agent-outlet-location/agency-agent-outlet-location-modify")
);
const CurrentScreenCreate = React.lazy(() =>
  import("./pages/currentaccount/currentaccount-create")
);
const CurrentaccountComponent = React.lazy(() =>
  import("./pages/currentaccount/currentaccount-list")
);
const CurrentaccountModify = React.lazy(() =>
  import("./pages/currentaccount/currentaccount-modify")
);
const SupportpersonScreenCreate = React.lazy(() =>
  import("./pages/Support-Person/supportperson-create")
);
const SupportpersonComponent = React.lazy(() =>
  import("./pages/Support-Person/supportperson-list")
);
const SupportpersonModify = React.lazy(() =>
  import("./pages/Support-Person/supportperson-modify")
);
const AgentaccountComponent = React.lazy(() =>
  import("./pages/agent-accountopen/account-lists")
);
const AgentAccountScreenModify = React.lazy(() =>
  import("./pages/agent-accountopen/account-modify")
);
const AccountScreencreater = React.lazy(() =>
  import("./pages/agent-accountopen/account-creater")
);
const AgentenadisScreenModify = React.lazy(() =>
  import("./pages/agent-enable-disable/agentenadis-modify")
);
const AgentenadisComponent = React.lazy(() =>
  import("./pages/agent-enable-disable/agentenadis-lists")
);
const Agentenadis = React.lazy(() =>
  import("./pages/agent-enable-disable/agentenadis-creater")
);
const CorporateScreen = React.lazy(() =>
  import("./pages/corporate-agent/corporate-creater")
);
const CorporateagentComponent = React.lazy(() =>
  import("./pages/corporate-agent/corporate-lists")
);
const CorporateagentModify = React.lazy(() =>
  import("./pages/corporate-agent/corporate-modify")
);
const RecurringComponent = React.lazy(() =>
  import("./pages/recurring-accountopen/recurring-creater")
);
const RecurringAcc = React.lazy(() =>
  import("./pages/recurring-accountopen/recurring-lists")
);
const RecurraccopenModify = React.lazy(() =>
  import("./pages/recurring-accountopen/recurring-modify")
);
const Recdepinst = React.lazy(() =>
  import("./pages/recurring-deposit-inst/recdepinst-lists")
);

const RecdepinstScreenCreate = React.lazy(() =>
  import("./pages/recurring-deposit-inst/recdepinst-creater")
);
const RecdepinstModify = React.lazy(() =>
  import("./pages/recurring-deposit-inst/recdepinst-modify")
);

const LoanleadScreencreater = React.lazy(() =>
  import("./pages/loanlead/loanlead-creater")
);
const Loan = React.lazy(() =>
  import("./pages/loanlead/loanlead-lists")
);
const LoanleadModify = React.lazy(() =>
  import("./pages/loanlead/loanlead-modify")
);

const AuthorisationMaintenanceComponent = React.lazy(() =>
  import("./pages/authorisation-queue/maintenance/authorisation-maintenance")
);

const AuthorisationTransactionComponent = React.lazy(() =>
  import("./pages/authorisation-queue/transactions/authorisation-transactions")
);
const SavingsaccountopenComponent = React.lazy(() =>
  import("./pages/savingsaccountopen/savingaccount-list")
);
const Savingsaccountopen = React.lazy(() =>
  import("./pages/savingsaccountopen/savingaccount-create")
);
const SavingsModify = React.lazy(() =>
  import("./pages/savingsaccountopen/savingaccount-modify")
);


const Menu = React.lazy(() =>
  import("./pages/menu/menu")
);

const InterfaceList = React.lazy(() =>
  import("./pages/Interface/interface")
);

const InterfaceBillPayment = React.lazy(() =>
  import("./pages/Interface/billpay")
);



const InterfaceCashDeposite = React.lazy(() =>
  import("./pages/Interface/cashdeposittransaction")
);

const InterfaceCashwithdrawal = React.lazy(() =>
  import("./pages/Interface/cashwithdrawal")
);


const InterfaceCashTransactions = React.lazy(() =>
  import("./pages/Interface/cashtransactions")
);

const InterfaceTransactionReport = React.lazy(() =>
  import("./pages/Interface/transactionReport")
);

const InterfaceBalanceEnquire = React.lazy(() =>
  import("./pages/Interface/balanceenquiry")
);


const InterfaceMiniStatement = React.lazy(() =>
  import("./pages/Interface/ministatement")
);

const menuCard = React.lazy(() =>
  import("./pages/menu/menucard")
);

const Interface = React.lazy(() =>
  import("./pages/Interface/interface")
);

const CorporatecustomerComponent = React.lazy(() =>
  import("./pages/corporate-customer/customer-list")
);
const Customercorporate = React.lazy(() =>
  import("./pages/corporate-customer/customer-create.")
);
const CustomerModify = React.lazy(() =>
  import("./pages/corporate-customer/customer-modify")
);

const Confirmdetails = React.lazy(() =>
  import("./pages/Interface/confirmdetails")
);
const Finaldetails = React.lazy(() =>
  import("./pages/Interface/finalconfirmdetails")
);
const Successdeposit = React.lazy(() =>
  import("./pages/Interface/succesdeposit")
);

const KycComponentlists = React.lazy(() =>
  import("./pages/agentkyc-send/agentkyc-lists")
);

const KycComponent = React.lazy(() =>
  import("./pages/agency-registration/agency-registration-list")
);

const KycScreenCreate = React.lazy(() =>
  import("./pages/agency-registration/agency-registration-create")
);
const KycScreenModify = React.lazy(() =>
  import("./pages/agency-registration/agency-registration-modify")
);

const UserLoginscreen = React.lazy(() =>
  import("./pages/user-creater/usercreater")//
);

const InterfaceCashwithdrawalTransaction = React.lazy(() =>
  import("./pages/Interface/cashwithdrawaltransaction")
);


// const UserLoginscreenComponent = React.lazy(() =>
//   import("./pages/user-creater/userlist")
// );


const AgentUserComponent = React.lazy(() =>
  import("./pages/agentuser/agentuser-list")
);

const AgentUserCreate = React.lazy(() =>
  import("./pages/agentuser/agentuser-create")
);


const Registeredreport = React.lazy(() =>
  import("./pages/Agent-Report/registerd-report")
)

const AgentTransDatewise = React.lazy(() =>
  import("./pages/Agent-Report/agent-transaction-datewise-report")
)


const Unsuccess = React.lazy(() =>
  import("./pages/Agent-Report/admin-unsuccessful-report")
)

const UnsuccessFul = React.lazy(() =>
  import("./pages/Agent-Report/unsuccessful-reports")
)



const GepgReport = React.lazy(() =>
  import("./pages/Agent-Report/GEPG-transaction")
)

const Utilitytransaction = React.lazy(() =>
  import("./pages/Agent-Report/utility-transaction")
)

const DepositeSummaries = React.lazy(() =>
  import("./pages/Agent-Report/agentdeposite-summary")
)

const WithdrawlSummaries = React.lazy(() =>
  import("./pages/Agent-Report/agentwithdraw-summary")
)

const Reversedtransaction = React.lazy(() =>
  import("./pages/Agent-Report/admin-reversed-report")
)

const Reversedtransactions = React.lazy(() =>
  import("./pages/Agent-Report/reversed-reports")
)

const AgenttoAgent = React.lazy(() =>
  import("./pages/Agent-Report/agent-to-agent")
)

const Bankingcommission = React.lazy(() =>
  import("./pages/Agent-Report/bankingCommission")
)
const Commissiondistribution = React.lazy(() =>
  import("./pages/Agent-Report/banking-commisition-distribution")
)
const BankingDistribution = React.lazy(() =>
  import("./pages/Agent-Report/bankingDistribution")
)

const Agentlogin = React.lazy(() =>
  import("./pages/Agent-Report/agentLogin")
)

const Agentdomancy = React.lazy(() =>
  import("./pages/Agent-Report/agent-domancy")
)

const Agentaccount = React.lazy(() =>
  import("./pages/agent-account/Agent-account")
)

const BillPaymentTransaction = React.lazy(() =>
  import("./pages/Interface/billpaymenttransaction")
);

/*const CommissionSlabCreate = React.lazy(() =>
  import("./pages/commissionslab/commissionslab-create")
);

const CommissionSlabSearch = React.lazy(() =>
  import("./pages/commissionslab/commissionslab-search")
);

const CommissionSlab = React.lazy(() =>
  import("./pages/commissionslab/commissionslab-ist")
);

const CommissionSlabModify = React.lazy(() =>
  import("./pages/commissionslab/commissionslab-modify")
);*/


//commission 


const CommissionCreate = React.lazy(() =>
  import("./pages/commission-slab/commission-slab-create")
);

const CommissionSearch = React.lazy(() =>
  import("./pages/commission-slab/commission-slab-search")
);

const CommissionSList = React.lazy(() =>
  import("./pages/commission-slab/commission-slab-list")
);

const CommissionModify = React.lazy(() =>
  import("./pages/commission-slab/commission-slab-modify")
);

const CommissionView = React.lazy(() =>
  import("./pages/commission-slab/comission-slab-view")
);

const BulkCommissionPost = React.lazy(() =>
  import("./pages/commission-slab/bulk-commission-post-create")
);
const BulkCommissionPostAuthorise = React.lazy(() =>
  import("./pages/commission-slab/bulk-commission-post-authorise")
);
const BulkCommission = React.lazy(() =>
  import("./pages/commission-slab/bulk-commission")
);



const Agenttoagenttransaction = React.lazy(() =>
  import("./pages/agent-to-agent/agenttoagent-transaction")
);

const Deviceallocationcreate = React.lazy(() =>
  import("./pages/device-details/agentdevice-create")
);

const Deviceallocation = React.lazy(() =>
  import("./pages/device-details/agentdevice-list")
);


const DeviceUpload = React.lazy(() =>
  import("./pages/device-details/agentdevice-upload")
);

const Deviceallocationmodify = React.lazy(() =>
  import("./pages/device-details/agentdevice-modify")
);

const Agentallocationlimits = React.lazy(() =>
  import("./pages/device-details/allocation-limits")
);

const CorporateAgent = React.lazy(() =>
  import("./pages/corporate-agentkyc/corporateagent-list")
);

const TransactionReport = React.lazy(() =>
  import("./pages/Agent-Report/transaction-datewise-report")
);

const LoginReport = React.lazy(() =>
  import("./pages/Agent-Report/agentLoginReport")
);

const UnsuccessfullAllReport = React.lazy(() =>
  import("./pages/Agent-Report/unsuccessful-reports")
);

const DepositeSummary = React.lazy(() =>
  import("./pages/Agent-Report/deposite-summary")
)

const WithdrawlSummary = React.lazy(() =>
  import("./pages/Agent-Report/withdraw-summary")
)
const Loginreports = React.lazy(() =>
  import("./pages/Agent-Report/agentlogin-reports")
);

// BANK-REPORTS

const Banktransaction = React.lazy(() =>
  import("./pages/bank-report/transactionDatewise-report")
);

const BankRegistration = React.lazy(() =>
  import("./pages/bank-report/registration-report")
);
const BankCashDeposite = React.lazy(() =>
  import("./pages/bank-report/cashdeposite-report")
);

const BankCashWithdrawal = React.lazy(() =>
  import("./pages/bank-report/cash-withdrawal-report")
);

const BankUtility = React.lazy(() =>
  import("./pages/bank-report/utility-report")
);

const ReverseTransaction = React.lazy(() =>
  import("./pages/bank-report/reverse-transaction-report")
);

const LoginReports = React.lazy(() =>
  import("./pages/bank-report/login-reports")
);

const CommissionDistribution = React.lazy(() =>
  import("./pages/bank-report/commission_distribution")
);


const BankUnsuccessfulTransactions = React.lazy(() =>
  import("./pages/bank-report/unsuccessful-reports")
)


const BankGepg = React.lazy(() =>
  import("./pages/bank-report/GEPG-transaction")
)

const BankagentbankingCommission = React.lazy(() =>
  import("./pages/bank-report/bankingCommission")
)

const CommissionPostingReport = React.lazy(() =>
  import("./pages/bank-report/commission-posting-report")
)

const CommissionDetailsReport = React.lazy(() =>
  import("./pages/bank-report/commssion-details-report")
)





const Transactionenabledisablecreate = React.lazy(() =>
  import("./pages/transaction-enabledisable/transaction-enabledisable-create")
);
const Transenabledisablelist = React.lazy(() =>
  import("./pages/transaction-enabledisable/transaction-eabledisbale-list")
);
const Transenabledisablemodify = React.lazy(() =>
  import("./pages/transaction-enabledisable/transaction-enabledisable-modify")
);

const Addingagentstoagencys = React.lazy(() =>
  import("./pages/agency-registration/addingagenttoagencylist")
)

const Addingagentstoagencyscreate = React.lazy(() =>
  import("./pages/agency-registration/addingagenttoagencycreate")
);

const Addingagentstoagencyupdate = React.lazy(() =>
  import("./pages/agency-registration/addingagenttoagencyupdate")
);

const Transactinlimits = React.lazy(() =>
  import("./pages/transaction -limits/transaction-limit-list")
);

const Transactinlimitscreate = React.lazy(() =>
  import("./pages/transaction -limits/transaction-limit-create")
);

const Transactionmodify = React.lazy(() =>
  import("./pages/transaction -limits/transaction-limit-modify")
);

const AgencyTransactinlimits = React.lazy(() =>
  import("./pages/agency-transaction-limit/agency-transaction-list")
);

const AgencyTransactinlimitscreate = React.lazy(() =>
  import("./pages/agency-transaction-limit/agency-transaction-create")
);

const AgencyTransactionmodify = React.lazy(() =>
  import("./pages/agency-transaction-limit/agency-transaction-modify")
);

const RetailAgentlist = React.lazy(() =>
  import("./pages/retail-agent-registration/retail-agent-registration-list")
);
const RetailAgentCreate = React.lazy(() =>
  import("./pages/retail-agent-registration/retail-agent-registration-create")
);
const RetailAgentModify = React.lazy(() =>
  import("./pages/retail-agent-registration/retail-agent-registration-modify")

);

const RetailAgentlimit = React.lazy(() =>
  import("./pages/retail-agent-registration/retail-agent-registration-limits"));



const Agencylimit = React.lazy(() =>
  import("./pages/agency-registration/agency-registration-limits"));



const Agentoutlectlocation = React.lazy(() =>
  import("./pages/agent-outlet-location/agent-outlet-list")
);
const Agentoutletcreate = React.lazy(() =>
  import("./pages/agent-outlet-location/agent-outlet-create")
);
const Agentoutletmodify = React.lazy(() =>
  import("./pages/agent-outlet-location/agent-outlet-modify")
);

const RetailsAgentmapping = React.lazy(() =>
  import("./pages/retailagent-loctaionMapping/retail-agent-list")
);

const RetailsAgentcreate = React.lazy(() =>
  import("./pages/retailagent-loctaionMapping/retails-agent-create")
);

const RetailAgentmodify = React.lazy(() =>
  import("./pages/retailagent-loctaionMapping/retails-agent-modify")
);

const CorporateAgentLimit = React.lazy(() =>
  import("./pages/corporateAgentLimit/corporateagentlimit-create"))

const CorporateAgentLimitList = React.lazy(() =>
  import("./pages/corporateAgentLimit/corporateagentlimit-list")
)

const CorporateAgentLimitModify = React.lazy(() =>
  import("./pages/corporateAgentLimit/corporateagentlimit-modify"))


const AgentTransactionLimit = React.lazy(() =>
  import("./pages/retailagentTransactionlimit/retail-agenttransactionlimit-create")
);
const AgentTransactionList = React.lazy(() =>
  import("./pages/retailagentTransactionlimit/retail-agentTransactionList")
);
const AgentTransactionmodify = React.lazy(() =>
  import("./pages/retailagentTransactionlimit/retail-agenttransaction-modify")
);


const RetailAgentWithdrwalLimitCreate = React.lazy(() =>
  import("./pages/retail-agent-withrawal-limit/retail-agent-withrawal-limit-create")
);
const RetailAgentWithdrwalLimitList = React.lazy(() =>
  import("./pages/retail-agent-withrawal-limit/retail-agent-withrawal-limit-list")
);
const RetailAgentWithdrwalLimitModify = React.lazy(() =>
  import("./pages/retail-agent-withrawal-limit/retail-agent-withrawal-limit-modify")
);


const RetailAgentTransactionenabledisablecreate = React.lazy(() =>
  import("./pages/retail-agent-transaction-enable-disable/retail-agent-transaction-enable-disable-create")
);
const RetailAgentTransenabledisablelist = React.lazy(() =>
  import("./pages/retail-agent-transaction-enable-disable/retail-agent-transaction-enable-disable-list")
);
const RetailAgentTransenabledisablemodify = React.lazy(() =>
  import("./pages/retail-agent-transaction-enable-disable/retail-agent-transaction-enable-disable-modify")
);

const ResetPin = React.lazy(() =>
  import("./pages/core-setup/agent-activity/reset-pin")
);

const UserPasswordResetComponent = React.lazy(() =>
  import("./pages/core-setup/user-password-reset/user-password-reset")
);

const ViewLocationList = React.lazy(() =>
  import("./pages/agency-admin/view-location-list")
);

const DashboardAgency = React.lazy(() =>
  import("./pages/dashboard-agency/dashboard")
);
const DeviceBatchAuthorise = React.lazy(() =>
  import("./pages/device-details/batch-authorise")
);

const DynamicForm = React.lazy(() =>
  import("./pages/DynamicForm/DynamicForm")
);
export default function Router() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route
          path="/dashboard"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Dashboard />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/totalregisteredagents"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DashboardTotalAgentRegistered />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/activeagent"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DashboardActiveAgents />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/inactiveagent"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DashboardInactiveAgents />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/failedregisteredagents"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DashboardFailedRegisteredAgents />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="/activedeactiveagents"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DashboardBlockedUnBlockedAgents />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/pendingforauthorisation"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <PendingForAuthorisation />
              </Suspense>
            </Layout>
          }
        />




        <Route
          path="/user"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <UserComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/interface/fundtransfer"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <FundScreen />
              </Suspense>
            </Layout>
          }
        />

        {/* <Route
          path="/fundtransfer-details"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <FundtransferDetails />
              </Suspense>
            </Layout>
          }
        /> */}


        <Route
          path="/agentuser/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentUserCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentuser"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentUserComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/user/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <UserCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/user/modify/:id"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <UserModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/user/enabledisable/:id"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <UserEnableDisable />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/userbrachassigment"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <UserBranchComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/userbrachassigment/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <UserBranchCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/userbrachassigment/modify/:id"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <UserBranchModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/role"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RolesComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/role/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RoleCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/role/modify/:id"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RoleModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/screen"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ScreenComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/screen/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ScreenCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/screen/modify/:id"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ScreenModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/rolescreenlist"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RoleScreenComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/rolescreen/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RoleScreenCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/rolescreen/modify/:roleId"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RoleScreenModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/changepassword"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ChangePasswordComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/useraccountunlock"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <UserAccountUnlockComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/state"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <StateComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/state/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <StateScreenCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/state/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <StateScreenModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/district"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DistrictComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/district/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DistrictCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/district/modify/:id"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DistrictModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/taluka"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <TalukComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/taluka/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <TalukCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/taluka/modify/:id"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <TalukModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/village"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <VillageComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/village/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <VillageScreenCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/interface"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Interface />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/menulist"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Menu />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/interface/cashdeposittransaction"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <InterfaceCashDeposite />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="/interface/Cashwithdrawal"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <InterfaceCashwithdrawal />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/interface/cashtransactions"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <InterfaceCashTransactions />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/interface/transactionReport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <InterfaceTransactionReport />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/interface/balanceenquire"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <InterfaceBalanceEnquire />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="/interface/ministatement"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <InterfaceMiniStatement />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/interface/billpay"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <InterfaceBillPayment />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/interface"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <InterfaceList />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/interface/billpay"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <InterfaceBillPayment />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="/village/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <VillageScreenModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/accountparameter"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AccountParameterComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/accountparameter/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AccountParameterScreenCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/accountparameter/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AccountParameterScreenModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/accounttype"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AccountTypeLimitComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/accounttype/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AccountTypeLimitScreenCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/accounttype/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AccountTypeLimitScreenModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/bankparamter"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BankParameterComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/bankparamter/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BankParameterScreenCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/bankparamter/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BankParameterScreenModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/biometric"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BiometricComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/biometric/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BiometricScreenCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/biometric/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BiometricScreenModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/request/reset-ib-user-password"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ResetIBUserPasswordComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/collection"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CollectionComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/collection/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CollectionScreenCreate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/collection/modify/:id"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CollectionScreenModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/category/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CategoryScreenCreate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/category"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CategoryComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/bank"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BankComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/bank/modify/:id"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BankModifyComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/accounttransactionlimit"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Accountlimit />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/accounttransactionlimit/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AccountlimitModifyComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/accounttransactionlimit/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AccountLimitCreate />
              </Suspense>
            </Layout>
          }
        />



        <Route
          path="/branch"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BranchComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/branch/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BranchCreateComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/branch/modify/:id"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BranchModifyComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/changePassword"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ChangePasswordComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/state"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <StateComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/state/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <StateScreenCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/state/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <StateScreenModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/village"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <VillageComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/village/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <VillageScreenCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/village/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <VillageScreenModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/collection/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CollectionScreenModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agentcategorymapping/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CategoryScreenModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/request/reset-ib-user-password"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ResetIBUserPasswordComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/collection"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CollectionComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/collection/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CollectionScreenCreate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agentcategorymapping/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CategoryScreenCreate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agentcategorymapping"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CategoryComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/alertparameter/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AlertScreenCreate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/alertparameter"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AlertComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/alertparameter/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AlertScreenModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agentpamter/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentScreenCreate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agentpamter"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agentpamter/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentScreenModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/fdrdparamter"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <FDRDComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/fdrdparamter/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <FDRDCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/fdrdparamter/modify/:id"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <FDRDModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/recurring"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RecurringDepositComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/recurring/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RecurringDepositCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/recurring/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RecurringDepositModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/fileextension"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <FileExtensionComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/fileextension/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <FileExtensionCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/fileextension/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <FileExtensionModify />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="/fileupload"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <FileUploadComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/fileupload/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <FileUploadCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/fileupload/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <FileUploadModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentmapping/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentmappingScreenCreate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/bcagentlocationmapping"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentmappingComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentmapping/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <  AgentmappingModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agencyagentoutletlocation/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgencyAgentOutletLocatiopnCreate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agencyagentoutletlocation"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgencyAgentOutletLocationList />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agencyagentoutletlocation/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgencyAgentOutletLocationModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/curentaccount/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CurrentScreenCreate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/authorisation/maintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AuthorisationMaintenanceComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/currentaccountopen"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CurrentaccountComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/currentaccountopen/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <  CurrentaccountModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/suppoerperson/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <SupportpersonScreenCreate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/supportperson"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <SupportpersonComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/supportperson/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <  SupportpersonModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/authorisation/transaction"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AuthorisationTransactionComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentaccountopen/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AccountScreencreater />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agentaccountopen"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentaccountComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentaccountopen/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentAccountScreenModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentenabledisable"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentenadisComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentenabledisable/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentenadisScreenModify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agentenabledisable/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Agentenadis />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/corporateagent/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CorporateScreen />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/corporateagent"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CorporateagentComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/corporateagent/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CorporateagentModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/recurringdepositeaccountopen"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RecurringAcc />
              </Suspense>


            </Layout>
          }
        />

        <Route
          path="/recurringdepositeinstallment"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Recdepinst />
              </Suspense>


            </Layout>
          }
        />

        <Route
          path="/recurringdepositeaccountopen/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RecurraccopenModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/recurringdepositeaccountopen/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RecurringComponent />
              </Suspense>
            </Layout>
          }
        />
        Recdepinst

        <Route
          path="/recurringdepositeinstallment"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Recdepinst />
              </Suspense>


            </Layout>
          }
        />

        <Route
          path="/recurringdepositeinstallment/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RecdepinstScreenCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/recurringdepositeinstallment/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RecdepinstModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/LoanLead/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <LoanleadScreencreater />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/LoanLead/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <LoanleadModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/LoanLead"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Loan />
              </Suspense>


            </Layout>
          }
        />
        <Route
          path="/savingaccountopen"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <SavingsaccountopenComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/savingaccountopen/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Savingsaccountopen />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/savingaccountopen/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <  SavingsModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/customer"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CorporatecustomerComponent />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/customer/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Customercorporate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/customer/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <  CustomerModify />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="/customerdetails"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <  Confirmdetails />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/finalconfirmdetails"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <  Finaldetails />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/finalconfirmdetails"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <  Finaldetails />
              </Suspense>
            </Layout>
          }
        />



        <Route
          path="/agencykyc"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <KycComponent />
              </Suspense>
            </Layout>
          }
        />

        {/* <Route
          path="/agencykyc/lists"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <KycComponentlists />
              </Suspense>
            </Layout>
          }
        /> */}



        <Route
          path="/agencykyc/creater"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <KycScreenCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agencykyc/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <KycScreenModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/interface/cashwithdrawaltransaction"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <InterfaceCashwithdrawalTransaction />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/registrationdetails"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Registeredreport />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/datewiseagenttransactionreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentTransDatewise />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/unsuccessfultransactions"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Unsuccess />

              </Suspense>
            </Layout>
          }
        />


        <Route
          path="/unsuccessfultransaction"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <UnsuccessFul />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agencygepgtransactions"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <GepgReport />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/utilitytransactionreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Utilitytransaction />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentcashdepoistreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DepositeSummaries />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentcashwithdrawalreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <WithdrawlSummaries />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/reversedtransactions"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Reversedtransaction />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/reversedtransaction"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Reversedtransactions />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agenttoagntreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgenttoAgent />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/commisioncalculation"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Bankingcommission />

              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agencybankingcommission"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Commissiondistribution />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agencybankingdistributions"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BankingDistribution />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/loginreports"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Agentlogin />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/domancyreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Agentdomancy />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/AgentAccount"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Agentaccount />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/billpaymenttransaction"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BillPaymentTransaction />

              </Suspense>
            </Layout>
          }
        />

        {/* <Route
          path="/commissionslab/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CommissionSlabCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/commissionslab/search"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CommissionSlabSearch />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/commissionslab"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CommissionSlab />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/commissionslab/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CommissionSlabModify />
              </Suspense>
            </Layout>
          }
        />*/}

        <Route
          path="/agenttoagents"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Agenttoagenttransaction />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentdeviceallocation/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Deviceallocationcreate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agentdeviceallocation"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Deviceallocation />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agentdeviceallocation/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Deviceallocationmodify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/deviceupload"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DeviceUpload />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/corporateagents"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CorporateAgent />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="/report/transaction"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <TransactionReport />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/report/loginreports"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <LoginReport />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/report/unsuccessfultransactions"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <UnsuccessfullAllReport />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/cashdepoistreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DepositeSummary />

              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/cashwithdrawalreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <WithdrawlSummary />

              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/report/agentloginreports"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Loginreports />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/bank/transactionreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Banktransaction />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/bank/registrationreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BankRegistration />
              </Suspense>
            </Layout>
          }
        />




        <Route
          path="/bank/unsuccessfultransactionsreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BankUnsuccessfulTransactions />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="bank/gepgtransactionseport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BankGepg />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="bank/agencybankingcommissionreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BankagentbankingCommission />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="bank/commissionpostingreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CommissionPostingReport />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="bank/commissiondetails/:agentId/:batchNo/"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CommissionDetailsReport />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="bank/agencybankingcommissiondistribustionreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CommissionDistribution />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="bank/cashdepositreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BankCashDeposite />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="bank/cashwithdrawalreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BankCashWithdrawal />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="bank/utilityreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BankUtility />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="bank/reversetransactionreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ReverseTransaction />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="bank/loginreport"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <LoginReports />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/transactionlimitenabledisable/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Transactionenabledisablecreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/transactionlimitenabledisable"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Transenabledisablelist />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/transactionlimitenabledisable/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Transenabledisablemodify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/addingagentstoagency"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Addingagentstoagencys />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/addingagentstoagency/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Addingagentstoagencyscreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/addingagentstoagency/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Addingagentstoagencyupdate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentallocationlimits"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Agentallocationlimits />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="/transctionlimitlist"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Transactinlimits />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/transctionlimitlist/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Transactinlimitscreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/transctionlimitlist/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Transactionmodify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agencytransctionlimitlist"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgencyTransactinlimits />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agencytransctionlimitlist/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgencyTransactinlimitscreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agencytransctionlimitlist/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgencyTransactionmodify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agent"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RetailAgentlist />
              </Suspense>
            </Layout>
          }
        />


        <Route
          // path="/agencylimits/:id"
          path="/agent/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RetailAgentCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agent/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RetailAgentModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agencylimits/:id"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Agencylimit />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentlimits/:id"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RetailAgentlimit />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentoutletlocation"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Agentoutlectlocation />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentoutletlocation/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Agentoutletcreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agentoutletlocation/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <Agentoutletmodify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/retailagentdevicemapping"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RetailsAgentmapping />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/retailagentdevicemapping/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RetailsAgentcreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/retailagentdevicemapping/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RetailAgentmodify />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/retailagenttransactionlimit/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentTransactionLimit />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/retailagenttransactionlimit"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentTransactionList />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/retailagenttransactionlimit/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <AgentTransactionmodify />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="/agencyagenttransactionlimit/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CorporateAgentLimit />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agencyagenttransactionlimit"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CorporateAgentLimitList />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/agencyagenttransactionlimit/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CorporateAgentLimitModify />
              </Suspense>
            </Layout>
          }
        />




        <Route
          path="/retailagentcashwithdrwallimit/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RetailAgentWithdrwalLimitCreate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/retailagentcashwithdrwallimit"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RetailAgentWithdrwalLimitList />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/retailagentcashwithdrwallimit/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RetailAgentWithdrwalLimitModify />
              </Suspense>
            </Layout>
          }
        />




        <Route
          path="/retailagenttransactionenabledisable/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RetailAgentTransactionenabledisablecreate />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/retailagenttransactionenabledisable"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RetailAgentTransenabledisablelist />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/retailagenttransactionenabledisable/modify/:id/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <RetailAgentTransenabledisablemodify />
              </Suspense>
            </Layout>
          }
        />




        <Route
          path="/commissionslab/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CommissionCreate />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/commissionslab/search"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CommissionSearch />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/commissionslab"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CommissionSList />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/commissionslab/modify/:type/:agentid/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CommissionModify />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="/commissionslab/view/:type/:agentid/:isMaintenance"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <CommissionView />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/commission/bulkpost/create"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BulkCommissionPost />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/commission/bulkpost/authorise"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BulkCommissionPostAuthorise />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/commission/bulkpost"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <BulkCommission />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="/resetpin"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ResetPin />
              </Suspense>
            </Layout>
          }
        />


        <Route
          path="/passwordreset"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <UserPasswordResetComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/viewlocation"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ViewLocationList />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/agencydashboard"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DashboardAgency />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/device/bulkauthorise"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DeviceBatchAuthorise />
              </Suspense>
            </Layout>
          }
        />
         <Route
          path="/dynamicform"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <DynamicForm />
              </Suspense>
            </Layout>
          }
        />
      </Routes>



      {/* </Layout> */}
    </HashRouter >
  );
}
