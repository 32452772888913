import { Dropdown, Menu, Select } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import LOGO from "../../assets/images/Azaniabanklog.png";
import notificationIcon from "../../assets/images/notification.png";
import profileIcon from "../../assets/images/profile.svg";
import { BiHelpCircle } from "react-icons/bi";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { useCallback } from "react";
import NotificationService from "../../services/notification.service";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../../services/auth.service";
import { MdMenu, MdDashboard } from "react-icons/md";
import { useMediaQuery } from '@material-ui/core';
import {
  loginDetailsSelector,
  permissionListSelector,
} from "../../store/selectors/app.selector";
const { Option, OptGroup } = Select;

const requestTypeOptions = [
  {
    menuId: 9,
    menuName: "Add CIF / Profile",
    parentMenuId: 8,
    permission: "----A",
    displayOrder: 0,
    menuKey: "ADD_PROFILE",
    isCreate: false,
    isRead: false,
    isUpdate: false,
    isDelete: false,
    isAuthorize: true,
    pathName: "/dashboard",
    subMenus: [],
  },
  {
    menuId: 11,
    menuName: "Update Mobile No",
    parentMenuId: 8,
    permission: "----A",
    displayOrder: 2,
    menuKey: "UPDATE_MOBILE_NO",
    isCreate: false,
    isRead: false,
    isUpdate: false,
    isDelete: false,
    isAuthorize: true,
    pathName: "/dashboard",
    subMenus: [],
  },
  {
    menuId: 12,
    menuName: "Update Email Id",
    parentMenuId: 8,
    permission: "----A",
    displayOrder: 3,
    menuKey: "UPDATE_EMAIL_ID",
    isCreate: false,
    isRead: false,
    isUpdate: false,
    isDelete: false,
    isAuthorize: true,
    pathName: "/dashboard",
    subMenus: [],
  },
  {
    menuId: 13,
    menuName: "Enable Authorization Flow",
    parentMenuId: 8,
    permission: "----A",
    displayOrder: 4,
    menuKey: "ENABLE_AUTHORIZATION_FLOW",
    isCreate: false,
    isRead: false,
    isUpdate: false,
    isDelete: false,
    isAuthorize: true,
    pathName: "/dashboard",
    subMenus: [],
  },
];
const { SubMenu } = Menu;
export default function TopBanner({
  onChange,
  menuName,
  setMenuName,
  menuList,
  onOpenChange,
  openKeys,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginDetails = useSelector(loginDetailsSelector);
  // const menuList = useSelector(permissionListSelector);
  const location = useLocation();
  const [lastLoginTime, setLastLoginTime] = useState("");
  // const [menuName, setMenuName]= useState()
  // const root = '/' + location.pathname.split('/')[1]
  const isMobile = useMediaQuery('(max-width: 500px)');
  const isTablet = useMediaQuery('(max-width: 700px)');
  const root = "/" + location.pathname.split("/")[1];
  const logoutCallback = useCallback(async () => {
    try {
      const response = await AuthService.postLogout({});
      if (response.status === 200) {
        NotificationService.showSuccessMessage("Logged Out Successfully!");
        dispatch({ type: "USER_LOGOUT" });
        navigate("/login");
      }
    } catch (err) {
      NotificationService.showSuccessMessage("Logged Out Successfully!"); //response.data["message"]
      dispatch({ type: "USER_LOGOUT" });
      navigate("/login");
    }
  });

  useEffect(() => {
    setLastLoginTime(moment(loginDetails?.lastLoginTime).format("MMMM Do YYYY, h:mm:ss A"));
  }, [loginDetails]);

  const menu = (
    <Menu>
      <Menu.Item>
        {/* <Menu.Item key="HELP_SUPPORT">Help & Support</Menu.Item> */}
        {/* <Menu.Divider /> */}
        <Menu.Item
          key="CHANGE_PASSWORD"
          onClick={() => navigate("/changepassword")}
        >
          Change Password
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="LOGOUT" onClick={() => logoutCallback()}>
          Logout
        </Menu.Item>
      </Menu.Item>
    </Menu>
  );
  const [mobileMenu, setMobileMenu] = useState(false)
  useEffect(() => {
    menuList.map((options, idx) => {
      options["subMenus"] &&
        options["subMenus"].map((menu, index) => {
          if (location.pathname === menu.pathName) {
            setMenuName(menu.menuName);
          }
          if (location.pathname == "/dashboard") {
            setMenuName("Dashboard");
          }
        });

      if (location.pathname == options.pathName) {
        setMenuName(options.menuName);
      }
    });
  });
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };



  const handleMenuItemClick = () => {
    // Close the sidebar when a menu item is clicked
    setSidebarVisible(false);
  };
  // const onChange = useCallback(
  //   async (value) => {
  //     try {
  //       if (value === "UPDATE_MOBILE_NO") {
  //         navigate("/approvals/change-mobile");
  //       }
  //       if (value === "UPDATE_EMAIL_ID") {
  //         navigate("/approvals/change-email");
  //       }

  //       if (value === "ENABLE_AUTHORIZATION_FLOW") {
  //         navigate("/approvals/auth-flow");
  //       }
  //       menuList.map((options, idx) => {
  //         options["subMenus"] &&
  //           options["subMenus"].map((menu, index) => {
  //             if (root === menu.pathName) {
  //               setMenuName(menu.menuName)
  //             }
  //             if (location.pathname == "/dashboard") {
  //               setMenuName("Dashboard")
  //             }
  //           })
  //       })
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   },
  //   []
  // );

  return (
    <>
      {isMobile || isTablet ?
        (
          <>
            <div className=" " >
              <button onClick={toggleSidebar} style={{ background: '#044c74', border: '#044c74' }}>
                <MdMenu className="icon-style" />


              </button>

              <div className="">
                {
                  sidebarVisible && (
                    <div style={{
                      width: sidebarVisible ? '200px' : '0px',
                      background: sidebarVisible ? '#044c74' : '#044c74',
                      // height: 'calc(100vh - 56px)',
                      // zIndex: '100',
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                      marginTop: isMobile ? '850px' : isTablet ? '600px' : '100px',
                      marginLeft: '-20px',
                      height: '100vh'
                    }}
                    >

                      <NavLink
                        to="/dashboard"
                        className="menu-item-parent"
                        style={{ borderBottom: '1px solid #5c5a5a' }}
                        onClick={handleMenuItemClick}
                      >
                        <MdDashboard className="icon-style" />
                        <div className="menu-name">Dashboard</div>

                      </NavLink>


                      <>
                        {menuList.map((option, idx) => (
                          <>
                            {(option["subMenus"] != undefined && option["subMenus"].length > 0) ?
                              <Menu
                                mode="inline"
                                style={{
                                  background: "#044c74",
                                  color: "#fff",
                                  borderBottom: "1px solid rgb(92, 90, 90)",
                                  borderRight: "0px",
                                }}
                                openKeys={openKeys}
                                onOpenChange={onOpenChange}
                                defaultOpenKeys={["0"]}
                                selectedKeys={[root, location.pathname]}
                                onClick={handleMenuItemClick}
                              >
                                <SubMenu
                                  key={(idx + 1)}
                                  title={
                                    <span>
                                      {option.menuName}
                                    </span>
                                  }
                                >
                                  {option["subMenus"] &&
                                    option["subMenus"].map((menu, idx) => (
                                      <Menu.Item key={menu.pathName}>
                                        <NavLink to={menu.pathName.trim()}>{menu.menuName}</NavLink>
                                      </Menu.Item>

                                    ))}
                                </SubMenu>
                              </Menu>
                              :
                              <NavLink
                                to={option.pathName.trim()}
                                className={
                                  location?.pathname.includes(option.pathName.trim())
                                    ? "menu-item-parent selected"
                                    : "menu-item-parent"
                                }
                                style={{ borderBottom: "1px solid #5c5a5a" }}
                                onClick={handleMenuItemClick}
                              >
                                <div className="menu-name">{option.menuName}</div>
                              </NavLink>
                            }
                          </>
                        ))
                        }

                      </>
                    </div>

                  )}

              </div>
            </div>
            <img height={10} src={LOGO} />

            {!isMobile && isTablet && (
              <div className="selected-menu" style={{ marginLeft: 0 }}>
                {menuName}
              </div>
            )}



          </>
        ) : (
          <>
            <img height={30} src={LOGO} />
            <div className="selected-menu" style={{ marginLeft: -10 }}>
              {menuName}
            </div>
            <div className="d-flex top-banner-right " >
              {/* <div className="current-day-time-text">
                {moment(loginDetails["lastLoginTime"]).format(
                  "MMMM Do YYYY, h:mm:ss A"
                )}
              </div> */}
              {/* <div className="current-day-time-text">
                {moment(loginDetails["lastLoginTime"]).format(
                  "MMMM Do YYYY, h:mm:ss A"
                )}
              </div> */}
              <div className="current-day-time-text">
                {lastLoginTime}
              </div>
            </div>
          </>

        )}

      {/* <div style={{ display: "flex", alignItems: "center" }}>
        <img height={45} src={LOGO} />
        <div className="selected-menu" style={{ marginLeft: 30 }}>
          {menuName}
        </div>
      </div> */}

      <div className="d-flex top-banner-right">
        {/* <span style={{ marginRight: 20, fontSize: 16, fontWeight: 400 }}>
          Quick Search
        </span>
        <Select
          showSearch
          placeholder="Quick Search"
          optionFilterProp="children"
          style={{ width: 250, marginRight: 50 }}
          onChange={onChange}
        >
          <OptGroup label="Appovals">
            {requestTypeOptions.map((option, idx) => (
              <Option key={idx} value={option.menuKey}>
                {option.menuName}
              </Option>
            ))}
          </OptGroup>
        </Select> */}

        <span style={{ margin: "0 30px" }}>
          Hello! {loginDetails.user?.["userName"]}
        </span>

        <Dropdown
          arrow={{ pointAtCenter: true }}
          overlay={menu}
          trigger={["click"]}
        >
          <img height={30} src={profileIcon} />
        </Dropdown>
      </div>
    </>
  );
}
