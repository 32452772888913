import http from "./http.service";
import config from "./constant";

import { store } from "../store";
import { SET_AGENT_DETAILS } from "../store/constants";
import { json } from "react-router-dom";
const createAgentKyc = async (data) => {
    try {
        const reqParams = {
            ...data,
        };
        const response = await http.post(config.kycagent, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const modifyAgentKyc = async (data) => {
    try {
        const reqParams = {
            ...data,
        };
        const response = await http.put(config.kycagent, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const getAgentKycList = async (data) => {
    try {
        const reqParams = +data.id;
        const response = await http.get(
            config.kycagent +
            "/" +
            reqParams +
            "?" +
            "isMaintenance" +
            "=" +
            data.isMaintenance+
            "&"+
            "from=1"
        );
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const getAgentKycListlimit = async (id) => {
    try {
        // const reqParams = +data.id;
        const response = await http.get(
            config.kycagent +
            "/" +
            id

        );
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const getAgentKycFilter = async (data) => {
    try {
        const reqParams = {
            ...data,
        };
        const response = await http.post(config.kycagent_FILTER, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const getAgentdetailsKycFilter = async (data) => {
    try {
        const reqParams = {
            ...data,
        };
        const response = await http.post(config.KYC_AGENTS_DETAILS_FILTER, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};
const deleteAgentKyc = async (data) => {
    try {
        const reqParams = +data.id;
        const response = await http.delete(config.kycagent + "/" + reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const authorizeAgentKyc = async (data) => {
    try {
        const reqParams = data
        const response = await http.post(config.kycagent_AUTHORIZE, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const sendAgentKyc = async (data) => {
    try {
        const reqParams = data
        const response = await http.post(config.KYCAGENY_SENDKYCTOCBS, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

// const sendDetailsUser = async (data) => {
//     try {
//         const reqParams = data
//         const response = await http.get(config.KYCAGENCY_getkycdetails, reqParams);
//         return { status: response.status, data: response.data };
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
const getAllUserCreaterList = async (data) => {
    try {
        const reqParams = {
            ...data,
        };
        const response = await http.get(config.KYCAGENCY_getkycdetails, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const getAgentKycDetails = async (data) => {
    try {
        const reqParams = +data.id;
        const response = await http.get(
            config.kycagent +
            "/" +
            reqParams +
            "?" +
            "isMaintenance" +
            "=" +
            data.isMaintenance+
            "&"+
            "from=1"
        );

        store.dispatch({ type: SET_AGENT_DETAILS, payload: response.data });

        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};



const getAgentByCIF = async (data) => {
    try {
        const reqParams = data;
        const response = await http.get(
            config.KYCAGENT_CIF +
            "/" +
            reqParams +
            "?" +
            "isMaintenance" +
            "=" +
            "false"
        );
        // alert(response);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const getAgentCIF = async (data) => {

    try {

        const reqParams = {
            ...data,
        };
        const response = await http.post(config.AGENT_KYC, reqParams);

        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};


const getKycDetails = async (data) => {
    try {
        const reqParams = +data.id;
        const response = await http.get(
            config.KYC_DETAILS +
            reqParams +
            "?" +
            "isMaintenance" +
            "=" +
            data.isMaintenance
        );

        store.dispatch({ type: SET_AGENT_DETAILS, payload: response.data });

        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};



const DropdownAgency = async (data) => {
    // alert(JSON.stringify(data))
    try {
        const reqParams = {
            ...data,
        };
        const response = await http.post(config.AGENCY_DROPDOWN, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};


const GetAgentsByAgency = async (id) => {


    try {
        // const reqParams = +data.id;
        const response = await http.get(
            config.Agent_By_Id +
            "/" +
            id

        );

        // const response = await http.get(config.Agent_By_Id, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};
const getkycaget = async (data) => {
    try {
        const reqParams = {
            ...data,
        };
        const response = await http.get(config.kycagent, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};


const getagenttoagency = async (data) => {
    // alert(JSON.stringify(data))
    try {
        const reqParams = {
            ...data,
        };
        const response = await http.get(config.BANK_TRANS_DATEWISE, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const agentkyc = async (data) => {
    // alert(JSON.stringify(data))
    try {
        const reqParams = {
            ...data,
        };
        const response = await http.get(config.AGENTKYCLIST, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const getAgencyLimits = async (id) => {


    try {
        // const reqParams = +data.id;
        const response = await http.get(
            config.AGENCY_LIMITS +
            "/" +
            id

        );

        // const response = await http.get(config.Agent_By_Id, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const GetAgencyByBranchId = async (branchId) => {


    try {
        // const reqParams = +data.id;
        const response = await http.get(
            config.AGENCY_BY_BRANCHID +
            "/" +
            branchId

        );

        // const response = await http.get(config.Agent_By_Id, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};


const GetdashboardsCounts = async (data) => {
    try {
        const reqParams = data
        const response = await http.get(config.DASHBOARD_COUNTS, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};



const GetActiveDeactive = async (id) => {


    try {
        // const reqParams = +data.id;
        const response = await http.get(
            config.DASHBOARD_TYPE +
            "/" +
            id

        );

        // const response = await http.get(config.Agent_By_Id, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const GetAllAgentList = async (branchId) => {
    try {
        // const reqParams = +data.id;
        const response = await http.get(
            config.ALL_AGENT_LIST +
            "/" +
            branchId

        );

        // const response = await http.get(config.Agent_By_Id, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const getAllAgent = async (data) => {
    // alert(JSON.stringify(data))
    try {
        const reqParams = {
            ...data,
        };
        const response = await http.get(config.GETALLCORPORATEAGENCY, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};

const GetCbsAgentAccountList = async (data) => {
    // alert(JSON.stringify(data))
    try {
        const reqParams = {
            ...data,
        };
        const response = await http.post(config.AGENT_CBS_ACCOUNT_LIST, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};



const fetchByCbsCustomerId = async (data) => {

    try {

        const reqParams = {
            ...data,
        };
        const response = await http.post(config.FETCH_CBS_CIF_DEATILS, reqParams);

        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};



const GetAgencyAllAgentList = async (agencyId) => {
    try {
        // const reqParams = +data.id;
        const response = await http.get(
            config.AGENCY_ALL_AGENT_LIST +
            "/" +
            agencyId

        );

        // const response = await http.get(config.Agent_By_Id, reqParams);
        return { status: response.status, data: response.data };
    } catch (err) {
        return Promise.reject(err);
    }
};





const AgentKyc = {
    createAgentKyc,
    getAgentKycList,
    modifyAgentKyc,
    deleteAgentKyc,
    authorizeAgentKyc,
    getAgentKycFilter,
    // convertImages
    sendAgentKyc,
    getAllUserCreaterList,
    getAgentKycDetails,
    getAgentByCIF,
    getAgentCIF,
    getKycDetails,
    DropdownAgency,
    GetAgentsByAgency,
    getkycaget,
    getagenttoagency,
    getAgentdetailsKycFilter,
    agentkyc,
    getAgencyLimits,
    getAgentKycListlimit,
    GetAgencyByBranchId,
    GetdashboardsCounts,
    GetActiveDeactive,
    GetAllAgentList,
    getAllAgent,
    GetCbsAgentAccountList,
    fetchByCbsCustomerId,
    GetAgencyAllAgentList
};

export default AgentKyc;
