import http from "./http.service";
import config from "./constant";
import { store } from "../store";
import { SET_BRANCH_LIST } from "../store/constants";


const getBranchList = async (data) => {
  try {
    const reqParams = {
      ...data,
    };
    const response = await http.get(config.BRANCH_FIND, reqParams);

    store.dispatch({ type: SET_BRANCH_LIST, payload: response.data });

    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getBranchListWithId = async (data) => {
  try {
    const reqParams = +data.id;
    const response = await http.get(
      config.BRANCH_FIND +
        "/" +
        reqParams +
        "?" +
        "isMaintenance" +
        "=" +
        data.isMaintenance
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const postBranch = async (data) => {
  try {
    const reqParams = {
      ...data,
    };
    const response = await http.post(config.BRANCH_FIND, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const modifyBranch = async (data) => {
  try {
    const reqParams = {
      ...data,
    };
    const response = await http.put(config.BRANCH_FIND, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteBranch = async (data) => {
  try {
    const reqParams = +data.id
    const response = await http.delete(config.BRANCH_FIND + "/" + reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const authorizeBranch = async (data) => {
  try {
    const reqParams =data
    const response = await http.post(config.BRANCH_AUTHORIZE, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const BranchService = {
  getBranchList,
  postBranch,
  modifyBranch,
  deleteBranch,
  authorizeBranch,
  getBranchListWithId,
};

export default BranchService;
